import axios from 'axios';
import { getHeaders } from "helpers/functions";
import { APIURL } from "helpers/constants";

// Related to Tasks feature
export const assignUserToTask = async (taskSlug, userId) => {
  try {
    const response = await axios.put(`${APIURL}/api/tasks/${taskSlug}/assign`,
      { assignees: [userId] },
      { headers: getHeaders() }
    );
    return response.data;
  } catch (error) {
    throw new Error('Error assigning user to task: ' + error.message);
  }
};

export const updateTask = async (editedTask) => {
  try {
    const formData = new FormData();
    formData.append('name', editedTask.name);

    if (editedTask.start_date) {
      formData.append('start_date', editedTask.start_date);
    }
    if (editedTask.end_date) {
      formData.append('end_date', editedTask.end_date);
    }

    const response = await axios.put(`${APIURL}/api/tasks/update/${editedTask.slug}`, formData, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    throw new Error('Error updating task: ' + error.message);
  }
};

export const updateTaskOrder = async (milestoneId, orderedTasks) => {
  try {
    const data = orderedTasks.map((task, index) => ({
      id: task.id,
      order: index + 1,
    }));

    const response = await axios.put(`${APIURL}/api/milestones/${milestoneId}/tasks/order`, data, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    throw new Error('Error updating task order: ' + error.message);
  }
};

export const addTaskToMilestone = async (milestoneId, taskName) => {
  try {
    const formData = new FormData();
    formData.append('name', taskName);
    const response = await axios.post(`${APIURL}/api/milestones/${milestoneId}/tasks/add`,  formData, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    throw new Error('Error adding task: ' + error.message);
  }
};

export const deleteTask = async (taskSlug) => {
  try {
    const response = await axios.delete(`${APIURL}/api/tasks/${taskSlug}/delete`, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    throw new Error('Error deleting task: ' + error.message);
  }
};

// Related to Comments feature
export const getComments = async (taskId, commentType) => {
  try {
    const response = await axios.get(`${APIURL}/api/comments/target/${taskId}/type/${commentType}`, { headers: getHeaders() });
    return response.data;
  } catch (error) {
    throw new Error('Error fetching comments: ' + error.message);
  }
};

export const saveComment = async (taskId, comment, commentType) => {
  try {
    const formData = new FormData();
    formData.append('content', comment);
    formData.append('target_id', taskId);
    formData.append('type', commentType);
    const response = await axios.post(`${APIURL}/api/comments/add`, formData, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    throw new Error('Error adding comment: ' + error.message);
  }
};

export const updateComment = async (commentId, content, commentType) => {
  try {
    const formData = new FormData();
    formData.append('content', content);
    formData.append('type', commentType);
    const response = await axios.put(`${APIURL}/api/comments/edit/${commentId}`, formData, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    throw new Error('Error updating comment: ' + error.message);
  }
};

export const deleteComment = async (commentId) => {
  try {
    const response = await axios.delete(`${APIURL}/api/comments/delete/${commentId}`, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    throw new Error('Error deleting comment: ' + error.message);
  }
};

// Related to Notes feature
export const saveNotes = async (taskId, content, commentType) => {
  try {
    const formData = new FormData();
    formData.append('content', content);
    formData.append('target_id', taskId);
    formData.append('type', commentType);
    const response = await axios.post(`${APIURL}/api/memos/add`, formData, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    throw new Error('Error adding note: ' + error.message);
  }
};

export const deleteNote = async (noteId) => {
  try {
    const response = await axios.delete(`${APIURL}/api/memos/delete/${noteId}`, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    throw new Error('Error deleting note: ' + error.message);
  }
};

// Related to SubTask feature
export const addSubTask = async (task, content) => {
  try {
    const formData = new FormData();
    formData.append('name', content);
    const response = await axios.post(`${APIURL}/api/tasks/${task.slug}/add-subtask`, formData, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    throw new Error('Error adding subtask: ' + error.message);
  }
};

export const updateSubTask = async (editedSubTask) => {
  try {
    const formData = new FormData();
    formData.append('name', editedSubTask.name);

    if (editedSubTask.status) {
      formData.append('status', editedSubTask.status);
    }
    if (editedSubTask.progress) {
      formData.append('progress', editedSubTask.progress);
    }
    if (editedSubTask.start_date) {
      formData.append('start_date', editedSubTask.start_date);
    }

    if (editedSubTask.end_date) {
      formData.append('end_date', editedSubTask.end_date);
    }
    const response = await axios.put(`${APIURL}/api/subtasks/update/${editedSubTask.slug}`, formData, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    throw new Error('Error updating subTask: ' + error.message);
  }
};

export const updateSubTaskOrder = async (taskSlug, orderedSubtasks) => {
  try {
    const data = orderedSubtasks.map(subtask => ({
      id: subtask.id,
      order: subtask.order,
    }));

    const response = await axios.put(`${APIURL}/api/subtasks/${taskSlug}/order`, data, {
      headers: getHeaders(),
    });

    return response.data;
  } catch (error) {
    throw new Error('Error updating subtask order: ' + error.message);
  }
};

export const deleteSubTask = async (subtaskSlug) => {
  try {
    const response = await axios.delete(`${APIURL}/api/subtasks/${subtaskSlug}/delete`, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    throw new Error('Error deleting subtask: ' + error.message);
  }
};

// Related to Milestones feature
export const updateMilestone = async (milestone) => {
  try {
    const formData = new FormData();
    formData.append('name', milestone.name);

    const response = await axios.put(`${APIURL}/api/milestones/${milestone.id}/update`, formData, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    throw new Error('Error updating milestones: ' + error.message);
  }
};
