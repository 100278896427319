import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Box,
  Typography,
} from '@mui/material';
import CustomDatePicker from 'components/Input/CustomDatePicker';
import dayjs from 'dayjs';
import Iconify from 'components/Iconify/index';
import { mainYellowColor } from "helpers/constants";
import styles from './styles.module.scss';

const DateRangeDialogComponent = ({ open, onClose, onSave, initialStartDate, initialEndDate }) => {
  const [values, setValues] = useState({
    startDate: initialStartDate ? dayjs(initialStartDate) : null,
    endDate: initialEndDate ? dayjs(initialEndDate) : null,
  });
  const [error, setError] = useState('');

  useEffect(() => {
    setValues({
      startDate: initialStartDate ? dayjs(initialStartDate) : null,
      endDate: initialEndDate ? dayjs(initialEndDate) : null,
    });
  }, [initialStartDate, initialEndDate]);

  const handleSave = () => {
    const { startDate, endDate } = values;

    if (startDate && endDate && dayjs(endDate).isBefore(dayjs(startDate))) {
      setError('End date must be greater than start date.');
      return;
    }

    onSave({
      startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
      endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null
    });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Select Due Date for Task</DialogTitle>
      <Box sx={{ padding: '0 24px' }}>
        <CustomDatePicker
          name="startDate"
          values={values}
          setValues={setValues}
          size="small"
          labelName="Task's Start Date"
        />
        <CustomDatePicker
          name="endDate"
          values={values}
          setValues={setValues}
          size="small"
          labelName="Task's End Date"
        />
        {error && (
          <Typography color="error" sx={{ mt: 0 }}>
            {error}
          </Typography>
        )}
      </Box>
      <DialogActions>
        <Button onClick={handleSave} variant="contained" color="primary" className={styles.addBtnBase}>
          <span><Iconify icon="mdi:tick" width={24} height={24} color={mainYellowColor} /></span>
          Set
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DateRangeDialogComponent;