import axios from 'axios';
import { getHeaders } from "helpers/functions";
import { APIURL } from "helpers/constants";

export const fetchAccountInfoService = async () => {
  return await axios.get(`${APIURL}/api/user/view-profile`, { headers: getHeaders() });
};

export const updateProfileService = async (formData) => {
  return await axios.put(`${APIURL}/api/user/update-profile`, formData, { headers: getHeaders() });
};

export const fetchOtherAccountInfo = async (param) => {
  try {
    const response = await axios.get(`${APIURL}/api/other-profile/${param}`, { headers: getHeaders() });
    return response.data;
  } catch (error) {
    console.error('Error fetching account info:', error);
    throw error;
  }
};

export const fetchFollowDataService = async (query = '') => {
  try {
    const response = await axios.get(`${APIURL}/api/users/forFollowing`, {
      headers: getHeaders(),
      params: { search: query },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching follow data:', error);
    throw error;
  }
};

export const acceptFollowRequest = async (id) => {
  try {
    await axios.post(`${APIURL}/api/follow-request/accept/${id}`, {}, { headers: getHeaders() });
  } catch (error) {
    console.error('Error accepting follow request:', error);
    throw error;
  }
};

export const rejectFollowRequest = async (id) => {
  try {
    await axios.post(`${APIURL}/api/follow-request/reject/${id}`, {}, { headers: getHeaders() });
  } catch (error) {
    console.error('Error rejecting follow request:', error);
    throw error;
  }
};

export const sendFollowRequest = async (id) => {
  try {
    await axios.post(`${APIURL}/api/follow-request/send/${id}`, {}, { headers: getHeaders() });
  } catch (error) {
    console.error('Error sending follow request:', error);
    throw error;
  }
};

export const getUserSkill = async () => {
  try {
    const response = await axios.get(`${APIURL}/api/skills/user`, { headers: getHeaders() });
    return response;
  } catch (error) {
    throw new Error('Error fetching user skills: ' + error.message);
  }
}

export const addUserSkill = async (formData) => {
  try {
    const response = await axios.post(`${APIURL}/api/skills/add`,
      formData,
      { headers: getHeaders() }
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteUserSkill = async (skillId) => {
  try {
    const response = await axios.delete(`${APIURL}/api/skills/delete/${skillId}`, { headers: getHeaders() });
    return response;
  } catch (error) {
    throw new Error('Error deleting user skill: ' + error.message);
  }
}
