import React from 'react';
import { Skeleton, Chip, Card, CardContent, Button, Typography, Box } from '@mui/material';
import InputBase from 'components/Input/InputBase';
import randomColor from 'randomcolor';
import InfoIcon from '@mui/icons-material/Info';
import styles from '../styles.module.scss';

//--------------------------------------------------------------------------------------------------

export const ProfileSkills = ({
  skills,
  skillsLoading,
  handleDeleteSkill,
  newSkill,
  setNewSkill,
  handleAddSkill,
  newSkillError,
}) => (
  <Card sx={{ boxShadow: 3, mt: 0 }}>
    <Box className={styles.cardHeader}>
      <Typography className={styles.cardHeaderTitle}>My Skills</Typography>
    </Box>
    <CardContent>
      <Box className={styles.boxInputAddSkills}>
        <Box className='w-100'>
          <InputBase
            labelText=""
            value={newSkill}
            placeholder="New skill"
            handleChange={(e) => setNewSkill(e)}
            errorText={newSkillError}
            helperText=""
            size="small" />
        </Box>
        <Button className={styles.addOrSaveBtn} onClick={handleAddSkill}>Add</Button>
      </Box>

      <Box sx={{ height: 150 }}>
        {skillsLoading ? (<>
          <Skeleton variant="rectangular" width="100%" height={40} sx={{ mb: 2 }} />
          <Skeleton variant="rectangular" width="100%" height={40} sx={{ mb: 2 }} />
          <Skeleton variant="rectangular" width="100%" height={40} sx={{ mb: 2 }} />
        </>) : (
          skills.length > 0 ? (
            skills.map((skill) => (
              <Chip
                key={skill.id}
                label={skill.name}
                onDelete={() => handleDeleteSkill(skill)}
                className={styles.skillChip}
                sx={{
                  bgcolor: randomColor({ hue: 'random', luminosity: 'dark' }),
                  color: 'white',
                }}
              />
            ))
          ) : (
            <Box className={styles.noSkillBox}>
              <InfoIcon className={styles.iconInfo} />
              <Typography className={styles.noSkillTitle}>
                No skills available
              </Typography>
              <Typography className={styles.noSkillSubTitle}>
                Please add a skill to display here.
              </Typography>
            </Box>
          )
        )}
      </Box>
    </CardContent>
  </Card>
);
