import React, { useCallback } from 'react';
import { Button, CardHeader } from "reactstrap";
import styles from './styles.module.scss';

const GoogleSignInButton = ({ action }) => {
  const { REACT_APP_GOOGLE_CLIENT_ID, REACT_APP_GOOGLE_REDIRECT_URL_ENDPOINT } = process.env;

  const openGoogleLoginPage = useCallback(() => {
    const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth";
    const scope = [
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
    ].join(" ");

    const params = new URLSearchParams({
      response_type: "code",
      client_id: REACT_APP_GOOGLE_CLIENT_ID,
      redirect_uri: `${REACT_APP_GOOGLE_REDIRECT_URL_ENDPOINT}/google`,
      prompt: "select_account",
      access_type: "offline",
      scope,
    });
    const url = `${googleAuthUrl}?${params}`;
    window.location.href = url;
  }, [REACT_APP_GOOGLE_CLIENT_ID, REACT_APP_GOOGLE_REDIRECT_URL_ENDPOINT]);

  return (
    <CardHeader className="bg-transparent pb-4">
      <div className="text-muted text-center mt-2 mb-3">
        <small>{action} with</small>
      </div>
      <div className="btn-wrapper text-center">
        <Button
          className={`btn-neutral w-100 ${styles.btnLoginWithGoogle}`}
          color="default"
          href="#pablo"
          onClick={openGoogleLoginPage}>
          <span className="btn-inner--icon">
            <img
              alt="..."
              src={
                require("../../assets/img/icons/common/google.svg")
                  .default
              }
            />
          </span>
          <span className={styles.textActionBtn}>{action} with Google</span>
        </Button>
      </div>
    </CardHeader>
  );
};

export default GoogleSignInButton;
