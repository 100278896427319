/* eslint-disable no-loop-func */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FaPlay, FaRedo, FaAngleDown, FaAngleLeft, FaAngleRight, FaAngleUp } from 'react-icons/fa';
import { Box, Button, Grid, Typography } from '@mui/material';
import styles from './styles.module.scss';

//--------------------------------------------------------------------------------------------------

const GRID_SIZE = 20;
const INITIAL_SNAKE = [
  { x: 10, y: 10 },
  { x: 9, y: 10 },
  { x: 8, y: 10 },
];
const INITIAL_DIRECTION = { x: 0, y: -1 };
const INITIAL_FOOD = { x: 15, y: 15 };

const SnakeGameComponent = ({ fromCreatePlan }) => {
  const [snake, setSnake] = useState(INITIAL_SNAKE);
  const [direction, setDirection] = useState(INITIAL_DIRECTION);
  const [food, setFood] = useState(INITIAL_FOOD);
  const [gameOver, setGameOver] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [score, setScore] = useState(0);
  const gameLoopRef = useRef();
  const touchStartRef = useRef({ x: 0, y: 0 });

  const generateFood = useCallback(() => {
    let newFood;
    do {
      newFood = {
        x: Math.floor(Math.random() * GRID_SIZE),
        y: Math.floor(Math.random() * GRID_SIZE),
      };
    } while (snake.some((segment) => segment.x === newFood.x && segment.y === newFood.y));
    setFood(newFood);
  }, [snake]);

  const moveSnake = useCallback(() => {
    const newSnake = [...snake];
    const head = { x: newSnake[0].x + direction.x, y: newSnake[0].y + direction.y };
    if (
      head.x < 0 ||
      head.x >= GRID_SIZE ||
      head.y < 0 ||
      head.y >= GRID_SIZE ||
      newSnake.some((segment) => segment.x === head.x && segment.y === head.y)
    ) {
      setGameOver(true);
      return;
    }
    newSnake.unshift(head);
    if (head.x === food.x && head.y === food.y) {
      setScore((prevScore) => prevScore + 1);
      generateFood();
    } else {
      newSnake.pop();
    }
    setSnake(newSnake);
  }, [snake, direction, food, generateFood]);

  const handleKeyDown = useCallback((e) => {
    if (e.code === 'Space' && !gameStarted) {
      setGameStarted(true);
    } else if (gameStarted && !gameOver) {
      switch (e.key) {
        case 'ArrowUp':
          if (direction.y === 0) setDirection({ x: 0, y: -1 });
          break;
        case 'ArrowDown':
          if (direction.y === 0) setDirection({ x: 0, y: 1 });
          break;
        case 'ArrowLeft':
          if (direction.x === 0) setDirection({ x: -1, y: 0 });
          break;
        case 'ArrowRight':
          if (direction.x === 0) setDirection({ x: 1, y: 0 });
          break;
        default:
          break;
      }
    }
  }, [gameStarted, gameOver, direction]);

  const handleTouchStart = useCallback((e) => {
    touchStartRef.current = {
      x: e.touches[0].clientX,
      y: e.touches[0].clientY,
    };
  }, []);

  const handleTouchEnd = useCallback((e) => {
    if (!gameStarted) {
      setGameStarted(true);
      return;
    }
    if (gameStarted && !gameOver) {
      const touchEnd = {
        x: e.changedTouches[0].clientX,
        y: e.changedTouches[0].clientY,
      };
      const dx = touchEnd.x - touchStartRef.current.x;
      const dy = touchEnd.y - touchStartRef.current.y;
      if (Math.abs(dx) > Math.abs(dy)) {
        if (dx > 0 && direction.x === 0) setDirection({ x: 1, y: 0 });
        else if (dx < 0 && direction.x === 0) setDirection({ x: -1, y: 0 });
      } else {
        if (dy > 0 && direction.y === 0) setDirection({ x: 0, y: 1 });
        else if (dy < 0 && direction.y === 0) setDirection({ x: 0, y: -1 });
      }
    }
  }, [gameStarted, gameOver, direction]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchend', handleTouchEnd);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [handleKeyDown, handleTouchStart, handleTouchEnd]);

  useEffect(() => {
    if (gameStarted && !gameOver) {
      gameLoopRef.current = setInterval(moveSnake, 100);
    } else {
      clearInterval(gameLoopRef.current);
    }
    return () => clearInterval(gameLoopRef.current);
  }, [gameStarted, gameOver, moveSnake]);

  const resetGame = () => {
    setSnake(INITIAL_SNAKE);
    setDirection(INITIAL_DIRECTION);
    generateFood();
    setGameOver(false);
    setGameStarted(false);
    setScore(0);
  };

  const renderCell = (x, y) => {
    const isSnakeHead = snake[0].x === x && snake[0].y === y;
    const isSnakeBody = snake.some((segment, index) => segment.x === x && segment.y === y && index > 0);
    const isFood = food.x === x && food.y === y;
    let backgroundColor = 'black';

    if (isSnakeHead) {
      backgroundColor = 'white';
    } else if (isSnakeBody) {
      backgroundColor = 'gray';
    } else if (isFood) {
      backgroundColor = 'red';
    }

    return <Box key={`${x}-${y}`} sx={{ width: 16, height: 16, backgroundColor, border: 0 }} />;
  };

  return (
    <Box className={styles.loadingContainer}
      sx={{ ...(!fromCreatePlan && { minHeight: '90vh' }) }}>
      <Box className={styles.gameWrapper}>
        <Box className={styles.instructions}>
          {!gameStarted && (
            <Typography variant="caption" className={styles.instructionsText}>
              Press <Box component="span" className={styles.spaceHint}>SPACE</Box> to play
            </Typography>
          )}
          {gameStarted && !gameOver && (
            <Box className={styles.moveControl}>
              <Typography variant="body2" className={styles.moveText}>Move with</Typography>
              <Box className={styles.controlsGrid}>
                <Button variant="contained" className={styles.controlButton} onClick={() => setDirection({ x: -1, y: 0 })}><FaAngleLeft /></Button>
                <Button variant="contained" className={styles.controlButton} onClick={() => setDirection({ x: 1, y: 0 })}><FaAngleRight /></Button>
                <Button variant="contained" className={styles.controlButton} onClick={() => setDirection({ x: 0, y: -1 })}><FaAngleUp /></Button>
                <Button variant="contained" className={styles.controlButton} onClick={() => setDirection({ x: 0, y: 1 })}><FaAngleDown /></Button>
              </Box>
            </Box>
          )}
        </Box>
        {!gameOver && (
          <Grid container spacing={0} className={styles.gridContainer}>
            {Array.from({ length: GRID_SIZE * GRID_SIZE }).map((_, index) => {
              const x = index % GRID_SIZE;
              const y = Math.floor(index / GRID_SIZE);
              return (
                <Grid item key={index} className={styles.gridItem}>
                  {renderCell(x, y)}
                </Grid>
              );
            })}
          </Grid>
        )}
        {gameOver && (
          <Box className={styles.gameOver}>
            <Typography variant="h4" className={styles.scoreText}>Game Over</Typography>
            <Typography variant="h6" className={styles.scoreText}>Score: {score}</Typography>
            <Button variant="outlined" className={styles.buttonOutlined} onClick={resetGame}><FaRedo /></Button>
          </Box>
        )}
        <Box className={styles.footer}>
          {!gameOver && <Typography variant="h6" className={styles.footerScore}>{score}</Typography>}
          <Box>
            {!gameStarted && (
              <Button variant="outlined" className={styles.buttonOutlined} onClick={() => setGameStarted(true)}>
                <FaPlay />
              </Button>
            )}
            {!gameOver && (
              <Button variant="outlined" className={styles.buttonOutlined} onClick={resetGame}>
                <FaRedo />
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SnakeGameComponent;
