import { useState, useEffect } from "react";
import { fetchAccountInfoService, getUserSkill } from 'views/users/services';

//--------------------------------------------------------------------------------------------------

const useProfile = () => {
  const [profile, setProfile] = useState({
    mail: '',
    firstName: '',
    lastName: '',
    desc: '',
    address: '',
    occupation: '',
    phoneNumber: '',
    selectedImage: null,
    date_joined: ''
  });
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(false);
  const [skillsLoading, setSkillsLoading] = useState(false);

  useEffect(() => {
    const fetchProfileData = async () => {
      setLoading(true);
      try {
        const response = await fetchAccountInfoService();
        if (response.status === 200) {
          setProfile({
            mail: response.data?.email,
            firstName: response.data?.first_name,
            lastName: response.data?.last_name,
            desc: response.data?.description,
            selectedImage: response.data?.avatar,
            address: response.data?.address,
            occupation: response.data?.occupation,
            phoneNumber: response.data?.phone_number,
            dateJoined: response.data?.date_joined,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchSkills = async () => {
      setSkillsLoading(true);
      try {
        const response = await getUserSkill();
        const skillData = response.data.map(skill => ({ id: skill.id, name: skill.name }));
        setSkills(skillData);
      } catch (error) {
        console.error(error);
      } finally {
        setSkillsLoading(false);
      }
    };

    fetchProfileData();
    fetchSkills();
  }, []);

  return { profile, skills, loading, skillsLoading, setProfile, setSkills, setLoading };
};

export default useProfile;
