import React from 'react';
import { Container } from '@mui/material';
import styles from './styles.module.scss';

const Features = () => (
  <section id="features" className={styles.featuresSection}>
    <Container className="main-container" style={{ padding: '30px 0 70px 0' }}>
      <h2 className={styles.titleSection} style={{ color: 'lightgray' }}>Features</h2>
      <div className={styles.featuresGrid}>
        <div className={styles.feature} data-aos="fade-up">
          <img src={require("../../assets/landing-feature-image1.jpeg")}
            className={styles.imagesFeature} alt="AI-Powered Planning" />
          <p className={styles.titleCard}>AI-Powered Planning</p>
          <p className={styles.subTitleCard}>Our AI generates customized project plans based on your inputs.</p>
        </div>
        <div className={styles.feature} data-aos="fade-up" data-aos-delay="100">
          <img src={require("../../assets/landing-feature-image2.jpeg")}
            className={styles.imagesFeature} alt="Dynamic Updates" />
          <p className={styles.titleCard}>Dynamic Updates</p>
          <p className={styles.subTitleCard}>Plans can adapt to changes in real-time, ensuring you stay on track.</p>
        </div>
        <div className={styles.feature} data-aos="fade-up" data-aos-delay="200">
          <img src={require("../../assets/landing-feature-image3.webp")}
            className={styles.imagesFeature} alt="Easy Collaboration" />
          <p className={styles.titleCard}>Easy Collaboration</p>
          <p className={styles.subTitleCard}>Collaborate with your team seamlessly and keep everyone aligned.</p>
        </div>
        <div className={styles.feature} data-aos="fade-up" data-aos-delay="300">
          <img src={require("../../assets/landing-feature-image4.jpeg")}
            className={styles.imagesFeature} alt="Intuitive Interface" />
          <p className={styles.titleCard}>Intuitive Interface</p>
          <p className={styles.subTitleCard}>Our platform is user-friendly and easy to navigate, even for beginners.</p>
        </div>
      </div>
    </Container>
  </section>
);

export default Features;
