import React from 'react';
import { Form, Comment, Header } from 'semantic-ui-react';
import { Dialog, Box, Button, Typography, IconButton } from '@mui/material';
import ConfirmDialogComponent from '../Dialog/confirm';
import { useSelector } from 'react-redux';
import { mainYellowColor } from "helpers/constants";
import Iconify from 'components/Iconify/index';
import styles from './styles.module.scss';
import { btnPrimaryColor, mainRedColor } from 'helpers/constants';

const CommentDialogComponent = ({
  open,
  onClose,
  comments,
  handleCommentSubmit,
  handleCommentUpdate,
  handleCommentDelete,
}) => {
  const [commentText, setCommentText] = React.useState("");
  const [error, setError] = React.useState("");
  const [editingCommentId, setEditingCommentId] = React.useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [deletingCommentId, setDeletingCommentId] = React.useState(null);
  const user = useSelector((state) => state.user);

  const handleSubmit = () => {
    if (editingCommentId) {
      handleCommentUpdate(editingCommentId, commentText);
      setEditingCommentId(null);
      setCommentText("");
    } else if (commentText.trim()) {
      handleCommentSubmit({ text: commentText });
      setCommentText("");
      setError("");
    } else {
      setError("Please enter a comment before submitting.");
    }
  };

  const handleEditClick = (comment) => {
    setEditingCommentId(comment.comId);
    setCommentText(comment.text);
  };

  const handleDeleteClick = (commentId) => {
    setDeletingCommentId(commentId);
    setConfirmDialogOpen(true);
  };

  const handleCancelEdit = () => {
    setEditingCommentId(null);
    setCommentText("");
  };

  const handleConfirmDelete = () => {
    handleCommentDelete(deletingCommentId);
    setConfirmDialogOpen(false);
    setDeletingCommentId(null);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <Box sx={{ padding: '20px' }}>
        <Header as='h3' dividing className={styles.commentHeader}>
          Comments
        </Header>
        <Comment.Group style={{ maxHeight: '500px', overflowY: 'auto', scrollbarWidth: 'none', msOverflowStyle: 'none' }}
          className={styles.customScrollbar}>
          {comments.length > 0 ? (
            comments.map((comment, index) => (
              <Comment key={comment.comId || index}>
                <Comment.Avatar src={comment.avatarUrl} />
                <Comment.Content>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>
                      <Comment.Author as='a'>{comment.fullName}</Comment.Author>
                      <Comment.Metadata>
                        <div>{comment.created_at}</div>
                      </Comment.Metadata>
                    </Box>
                    {comment.userId === String(user.id) && editingCommentId !== comment.comId && (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                          onClick={() => handleEditClick(comment)}
                          size="small">
                          <Iconify icon='tabler:edit' width={24} height={24} color={btnPrimaryColor} />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDeleteClick(comment.comId)}
                          size="small">
                          <Iconify icon='ph:x-circle-bold' width={24} height={24} color={mainRedColor} />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                  <Comment.Text>
                    {comment.text}
                    {editingCommentId === comment.comId && (
                      <Box className={styles.commentEditingAction}>
                        <Typography variant="body2" color="textSecondary">Editing...</Typography>
                        <Typography variant="body2" color="textSecondary"
                          onClick={handleCancelEdit}
                          sx={{ ml: 1, cursor: 'pointer' }}>Cancel</Typography>
                      </Box>
                    )}
                  </Comment.Text>
                </Comment.Content>
              </Comment>
            ))
          ) : (
            <Box sx={{ padding: '10px', textAlign: 'center', color: 'gray', fontSize: '1.125rem' }}>
              No comments yet.
            </Box>
          )}
        </Comment.Group>
        <Form>
          <Box className={styles.titleTextarea}>
            {editingCommentId ? 'Edit comment' : 'Add new comment'}
          </Box>
          <Form.TextArea
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            rows={1}
            placeholder="Write your comment..."
            error={!!error}
            className={styles.commentTextArea}
          />
          {error && (
            <Typography color="error" variant="body2" sx={{ marginTop: '5px' }}>
              {error}
            </Typography>
          )}
          <Box className='d-flex justify-content-start align-items-center' sx={{ marginTop: '10px' }}>
            <Button onClick={handleSubmit} variant="contained" className={styles.addBtnBase}>
              <span><Iconify icon={editingCommentId ? 'tabler:edit' : 'gala:add'} width={24} height={24} color={mainYellowColor} /></span>
              {editingCommentId ? 'Update' : 'Add'}
            </Button>
          </Box>
        </Form>
      </Box>
      <ConfirmDialogComponent
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={handleConfirmDelete}
        title="Confirm Delete"
        description="Are you sure you want to delete this comment?"
      />
    </Dialog>
  );
};

export default CommentDialogComponent;
