import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { Container, Grid, Box } from "@mui/material";
import PublicRoute from "PublicRoute";
import routes from "routes/index";
import { COMMON_PAGE_KEY } from "helpers/constants";

const Auth = (props) => {
  const mainContent = React.useRef(null);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === COMMON_PAGE_KEY) {
        const RouteComponent = prop.private ? React.Fragment : PublicRoute;
        return (
          <Route
            path={prop.path}
            key={key}
            element={<RouteComponent>{prop.component}</RouteComponent>}
          />
        );
      }
      return null;
    });
  };

  return (
    <div className="main-content" ref={mainContent}>
      <Box
        className="header"
        sx={{ py: 2, backgroundColor: "#2b2b2b" }}>
        <Container>
          <Box className="header-body text-center">
            <Grid container justifyContent="center">
              <Grid item lg={5} md={6}>
                <a href="/">
                  <img
                    alt="..."
                    src={require("../assets/main_logo.png")}
                    style={{ width: "150px" }}
                  />
                </a>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>

      <Container className={`pb-5`}
        sx={{ minHeight: 'calc(100vh - 80px)', display: 'flex', alignItems: 'center' }} fluid>
        <Grid container justifyContent="center">
          <Routes>
            {getRoutes(routes)}
            <Route
              path="*"
              element={<PublicRoute><Navigate to="/login" replace /></PublicRoute>}
            />
          </Routes>
        </Grid>
      </Container>
    </div>
  );
};

export default Auth;
