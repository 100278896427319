import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  Typography,
  Box,
  Link
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DONE_STATUS, TODO_STATUS, primaryColor } from "helpers/constants";
import EditableLabel from 'react-inline-editing';
import CustomDatePicker from 'components/Input/CustomDatePicker';
import styles from './styles.module.scss';
import dayjs from 'dayjs';

const TaskTable = ({ tasks, onToggleComplete, onDelete, onUpdateTaskName, onUpdateTaskDate }) => {
  const handleToggleTask = (task) => {
    const newStatus = task.status === DONE_STATUS ? TODO_STATUS : DONE_STATUS;
    onToggleComplete(task.id, newStatus);
    task.subtasks.forEach(subtask => {
      subtask.status = newStatus;
    });
  };

  const handleNameChange = (taskId, newName) => {
    onUpdateTaskName(taskId, newName);
  };

  const handleStartDateChange = (taskId, updatedValues) => {
    onUpdateTaskDate({
      ...tasks.find(task => task.id === taskId),
      start_date: updatedValues.startDate ? updatedValues.startDate.format('YYYY-MM-DD') : null,
    });
  };

  const handleEndDateChange = (taskId, updatedValues) => {
    onUpdateTaskDate({
      ...tasks.find(task => task.id === taskId),
      end_date: updatedValues.endDate ? updatedValues.endDate.format('YYYY-MM-DD') : null,
    });
  };

  const renderSubtasks = (subtasks = []) => {
    return subtasks.map((subtask) => (
      <TableRow key={subtask.id} sx={{ backgroundColor: '#f9f9f9' }}>
        <TableCell>
          <Box ml={4}>
            <Checkbox
              checked={subtask.status === DONE_STATUS}
              onChange={() => handleToggleTask(subtask)}
            />
          </Box>
        </TableCell>
        <TableCell sx={{ textDecoration: subtask.status === DONE_STATUS ? 'line-through' : 'none' }}>
          <Box ml={4}>
            <Typography variant="body2">{subtask.name}</Typography>
          </Box>
        </TableCell>
        <TableCell colSpan={2}></TableCell>
        <TableCell>
          <IconButton edge="end" aria-label="delete" onClick={() => onDelete(subtask.id)}>
            <DeleteIcon size="small" />
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>Name</TableCell>
            <TableCell sx={{ width: '180px' }}>Start Date</TableCell>
            <TableCell sx={{ width: '180px' }}>End Date</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(tasks || []).map((task) => (
            <React.Fragment key={task.id}>
              <TableRow>
                <TableCell>
                  <Checkbox
                    checked={task.status === DONE_STATUS}
                    onChange={() => handleToggleTask(task)}
                  />
                </TableCell>
                <TableCell sx={{ textDecoration: task.status === DONE_STATUS ? 'line-through' : 'none' }}>
                  <EditableLabel
                    text={task.name}
                    labelClassName={styles.taskNameLabel}
                    inputClassName={styles.taskNameInput}
                    inputWidth="100%"
                    inputHeight="25px"
                    onFocusOut={(newName) => handleNameChange(task.id, newName)} />
                  {task.plan_name && (
                    <Typography variant="body2" sx={{ pl: "5px", color: primaryColor }}>
                      │ <Link href={`/d/plan/${task.plan_slug}`} underline="hover" sx={{ color: primaryColor }}>
                        {task.plan_name}
                      </Link>
                    </Typography>
                  )}
                </TableCell>
                <TableCell sx={{ width: '180px' }}>
                  <CustomDatePicker
                    values={{ startDate: task.start_date ? dayjs(task.start_date) : null }}
                    setValues={(updatedValues) => handleStartDateChange(task.id, updatedValues)}
                    name="startDate"
                    labelName=""
                    size="small"
                  />
                </TableCell>
                <TableCell sx={{ width: '180px' }}>
                  <CustomDatePicker
                    values={{ endDate: task.end_date ? dayjs(task.end_date) : null }}
                    setValues={(updatedValues) => handleEndDateChange(task.id, updatedValues)}
                    name="endDate"
                    labelName=""
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <IconButton edge="end" aria-label="delete" onClick={() => onDelete(task.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
              {renderSubtasks(task.subtasks)}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TaskTable;
