import React from 'react';
import styles from './styles.module.scss';

const Hero = () => (
  <section className={styles.hero}>
    <h1 className={styles.heroSologan}>Welcome to Your AI-Powered Project Planner</h1>
    <p className={styles.heroSubSologan}>Generate dynamic project plans tailored to your needs</p>
    <a className={styles.heroBtn} href="/login" >Get Started</a>
  </section>
);

export default Hero;
