import React, { useState } from "react";
import { Card, CardBody, FormGroup, Form, Row, Col, Spinner } from "reactstrap";
import { InputAdornment, IconButton, Button } from "@mui/material";
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import InputBase from 'components/Input/InputBase';
import InputPasswordBase from 'components/Input/InputPasswordBase';
import GoogleSignInButton from 'components/Button/GoogleSignInButton';
import Iconify from 'components/Iconify/index';
import { successSnackbar, errorSnackbar } from 'components/Snackbar/index';
import { APIURL, iconPrimaryColor } from "helpers/constants";
import styles from './styles.module.scss';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const navigate = useNavigate();

  const handleShowPass = () => {
    setShowPass(!showPass);
  };

  const handleChange = (field, value) => {
    if (field === 'email') {
      setEmail(value);
    } else if (field === 'password') {
      setPassword(value);
    }
  };

  const handleLogin = async () => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);

    setLoading(true);

    try {
      const response = await axios.post(`${APIURL}/api/user/login`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      const { access_token, refresh_token } = response.data;
      Cookies.set('accessToken', access_token, { expires: 7 });
      Cookies.set('refreshToken', refresh_token, { expires: 7 });
      successSnackbar("Login Success!");
      navigate('/d/', { replace: true });
      return response.data;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error_messages) {
        setErrors(error?.response?.data?.error_messages);
      } else {
        setErrors({});
      }
      const errorMessage = error?.response?.data?.error_message;
      if (errorMessage) {
        errorSnackbar(errorMessage, true);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleLogin();
    }
  };

  return (
    <Col lg="6" md="8">
      <Card className="bg-secondary shadow border-0">
        <GoogleSignInButton action="Sign in" />
        <CardBody className="py-lg-4">
          <div className="text-center text-muted mb-4">
            <small>Or sign in with credentials</small>
          </div>
          <Form role="form" onKeyPress={handleKeyPress}>
            <FormGroup className="mb-3">
              <InputBase
                labelText=""
                type="email"
                handleChange={handleChange}
                errorText={errors.email}
                placeholder="Email"
                value={email}
                keyword="email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="ic:baseline-email" width={24} color={iconPrimaryColor} />
                    </InputAdornment>
                  ),
                }}
              />
              {(errors?.email && errors?.email.length > 0) &&
                <div className="error-message">{errors?.email[0]}</div>}
            </FormGroup>
            <FormGroup>
              <InputPasswordBase
                labelText=""
                showPass={showPass}
                handleChange={handleChange}
                errorText={errors.password}
                placeholder="Password"
                value={password}
                name="password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify icon="material-symbols:lock" color={iconPrimaryColor} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPass} edge="end">
                        <Iconify icon={showPass ? 'mdi:eye-off' : 'mdi:eye'} color={iconPrimaryColor} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {(errors?.password && errors?.password.length > 0) &&
                <div className="error-message">{errors?.password[0]}</div>}
            </FormGroup>

            <div className="text-center">
              <Button onClick={handleLogin} variant="contained" className={styles.submitBtn}>
                {loading ? <Spinner size="sm" /> : 'Sign in'}
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
      <Row className="mt-3">
        <Col xs="6">
          <a href="/forgot-password" className={styles.customeALink}>
            Forgot password ?
          </a>
        </Col>
        <Col className="text-right" xs="6">
          <a href="/register" className={styles.customeALink}>
            Create new account
          </a>
        </Col>
      </Row>
    </Col>
  );
};

export default Login;
