import React from 'react';
import { Container } from '@mui/material';
import styles from './styles.module.scss';

const images = [
  { src: require("../../assets/landing-galary-image1.avif"), alt: "Gallery Image 1" },
  { src: require("../../assets/landing-galary-image2.avif"), alt: "Gallery Image 2" },
  { src: require("../../assets/landing-galary-image3.avif"), alt: "Gallery Image 3" },
  { src: require("../../assets/landing-galary-image4.avif"), alt: "Gallery Image 4" }
];

const Gallery = () => (
  <section id="gallery" className={styles.gallerySection}>
    <Container className="main-container" style={{ padding: '30px 0 70px 0' }}>
      <h2 className={styles.titleSection}>Gallery</h2>
      <div className={styles.galleryGrid}>
        {images.map((image, index) => (
          <div key={index}
            className={`${styles.galleryItem} ${index % 2 === 0 ? styles.large : styles.small}`}
            data-aos="zoom-in"
            data-aos-delay={index * 100}>
            <img src={image.src} alt={image.alt} className={styles.galleryImg} />
          </div>
        ))}
      </div>
    </Container>
  </section>
);

export default Gallery;
