import React from 'react';
import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';

const Iconify = ({ icon, width = 24, height = 24, color, ...other }) => {
  return (
    <Icon
      icon={icon}
      width={width}
      height={height}
      color={color}
      style={{ width: `${width}px`, height: `${height}px` }}
      {...other}
    />
  );
};

Iconify.propTypes = {
  icon: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default Iconify;
