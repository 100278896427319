import React from 'react';
import { TextField, Typography } from "@mui/material";

//--------------------------------------------------------------------------------------------------

const InputBase = (props) => {
  const {
    labelText,
    type,
    handleChange,
    errorText,
    placeholder,
    id,
    helperText,
    value,
    minRows,
    disabled = false,
    size,
    variant = "outlined",
    className,
    InputProps,
    required,
    multiline,
  } = props;

  const primaryColor = '#9E9991';
  const placeholderColor = '#888888';
  const primaryFont = 'Recursive Variable';

  return (<>
    <TextField
      disabled={disabled}
      multiline={!!multiline}
      minRows={minRows}
      error={!!errorText}
      helperText={helperText}
      label={labelText}
      size={size}
      fullWidth
      variant={variant}
      type={type}
      id={id}
      value={value ?? ""}
      placeholder={placeholder}
      className={className}
      onChange={(e) => props?.keyword
        ? handleChange(props?.keyword, e.target.value)
        : handleChange(e.target.value)
      }
      required={required}
      InputProps={InputProps}
      sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderWidth: '1px',
            borderColor: primaryColor,
            borderRadius: '5px',
            fontFamily: primaryFont,

          },
          '&:hover fieldset': {
            borderWidth: '1px',
            borderColor: disabled ? 'none' : primaryColor,
            fontFamily: primaryFont,
          },
          '&.Mui-focused fieldset': {
            borderWidth: '1px',
            borderColor: disabled ? 'none' : primaryColor,
            fontFamily: primaryFont,
          },
        },
        '& .MuiInputBase-input::placeholder': {
          fontSize: '1.125rem',
          color: placeholderColor,
          fontFamily: primaryFont,
        },
        '& .MuiInputBase-input': {
          display: 'flex',
          alignItems: 'center',
          height: size === 'small' ? '2.7rem' : '42px',
          padding: 0,
          paddingLeft: InputProps ? 0 : '14px',
          paddingRight: InputProps ? 0 : '14px',
          fontFamily: primaryFont,
          fontSize: '1.125rem',
        },
        backgroundColor: '#FFFFFF'
      }}
    />
    {errorText && (
      <Typography variant="body2" color="error" sx={{ marginTop: '5px', fontSize: '0.875rem' }}>
        {errorText}
      </Typography>
    )}
    </>
  );
}

export default InputBase;
