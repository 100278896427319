import React from 'react';
import { Dialog, Box, Typography, Button } from '@mui/material';
import InputBase from 'components/Input/InputBase';
import styles from '../styles.module.scss';
import Iconify from 'components/Iconify/index';
import { mainYellowColor } from "helpers/constants";

//--------------------------------------------------------------------------------------------------

// 10. TODO for Nghia
// tổng quan về luồng hoạt động của mời tham gia plan như sau
// nhập email -> tìm user thông qua email đó đã có trong hệ thống chưa
// nếu có rồi thì gửi lời mời, người nhận được lời mời sẽ mở email và xác nhận có tham gia plan
// nếu email đó chưa được user nào đăng ký thì vẫn gửi mail, khi mở link thông qua email nó sẽ cho người dùng đăng ký trước
// sau đó mới là hiển thị có đồng ý tham gia plan không
// 10.1 gửi mail cho người dùng với trường hợp là mời thông qua email user đã có trong hệ thống

const InviteDialogComponent = ({ open, onClose, email, handleEmailChange, handleSendEmail, inviteError }) => {
  console.log('InviteDialogComponent:: ', inviteError)
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Box className={styles.noteDialogContainer}>
        <Typography variant="h5" className={styles.noteTitle}>Invite Plan via Email</Typography>
        <Box className={styles.subtaskInputContainer}>
          <InputBase
            id="email-input"
            value={email}
            placeholder="Enter email address"
            handleChange={handleEmailChange}
            errorText={inviteError}
            required
            sx={{ flexGrow: 1, marginRight: 2 }}
          />
        </Box>
        <Box>
        <Button onClick={handleSendEmail} variant="contained" color="primary" className={styles.addBtnBase}>
            <span><Iconify icon="tabler:send" width={24} height={24} color={mainYellowColor} /></span>
            Send
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default InviteDialogComponent;
