import styles from './styles.module.scss';

const Header = ({ activeSection }) => {

  const handleScroll = (e, sectionId) => {
    e.preventDefault();
    const section = document.querySelector(sectionId);
    const offset = 160; // Define your offset value here
    const sectionPosition = section.offsetTop - offset; // Calculate the position minus the offset
    window.scrollTo({
      top: sectionPosition,
      behavior: 'smooth', // Optional: Adds smooth scroll behavior
    });
  };

  return (
    <header className={`${styles.header}`} style={{ padding: '20px 50px 20px 50px'}}>
      <div className={styles.logo}>
        <img alt="..." src={require("../../assets/main_logo.png")} style={{ width: "160px" }} />
      </div>
      <nav>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* Compare activeSection to determine which link is active */}
          <div className={`${styles.divHeader} ${activeSection === 'about' ? styles.active : ''}`}>
            <a className={styles.linkHeader} href="#about" onClick={(e) => handleScroll(e, '#about')}>About</a>
          </div>
          <div className={`${styles.divHeader} ${activeSection === 'features' ? styles.active : ''}`}>
            <a className={styles.linkHeader} href="#features" onClick={(e) => handleScroll(e, '#features')}>Features</a>
          </div>
          <div className={`${styles.divHeader} ${activeSection === 'services' ? styles.active : ''}`}>
            <a className={styles.linkHeader} href="#services" onClick={(e) => handleScroll(e, '#services')}>Services</a>
          </div>
          <div className={`${styles.divHeader} ${activeSection === 'gallery' ? styles.active : ''}`}>
            <a className={styles.linkHeader} href="#gallery" onClick={(e) => handleScroll(e, '#gallery')}>Gallery</a>
          </div>
          <div className={`${styles.divHeader} ${activeSection === 'testimonials' ? styles.active : ''}`}>
            <a className={styles.linkHeader} href="#testimonials" onClick={(e) => handleScroll(e, '#testimonials')}>Testimonials</a>
          </div>
          <div className={`${styles.divHeader} ${activeSection === 'contact' ? styles.active : ''}`}>
            <a className={styles.linkHeader} href="#contact" onClick={(e) => handleScroll(e, '#contact')}>Contact</a>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
