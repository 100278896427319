import React, { useState } from 'react';
import axios from 'axios';
import { Button, Container, Box, Typography, Tooltip, IconButton } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { getHeaders } from "helpers/functions";
import { APIURL, MAIN_LANGUES_OPTION, mainYellowColor } from "helpers/constants";
import { useNavigate } from 'react-router-dom';
import InputSelectBase from 'components/Input/InputSelectBase';
import TextAreaBase from 'components/Input/TextAreaBase';
import Iconify from 'components/Iconify/index';
import SnakeGameLoadingComponent from 'components/Loading/index';
import styles from './styles.module.scss';

//--------------------------------------------------------------------------------------------------

const CreatePlan = () => {
  const [promptInput, setPromptInput] = useState('');
  const [language, setLanguage] = useState('');
  const [plannerRole, setPlannerRole] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [planSlug, setPlanSlug] = useState('');
  const [isPlanCreated, setIsPlanCreated] = useState(false);

  const handleCreate = async () => {
    if (!promptInput.trim() || !plannerRole.trim() || !language.trim()) {
      setError('Please fill in all fields.');
      return;
    }

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("prompt", promptInput);
      formData.append("language", language);
      formData.append("role", plannerRole);
      const response = await axios.post(`${APIURL}/api/assistant/create-planner`,
        formData,
        { headers: getHeaders() }
      );
      setPlanSlug(response.data.slug);
      setIsPlanCreated(true);
    } catch (error) {
      console.error("Plan generation faced an error", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNavigateToPlan = () => {
    navigate("/d/plan/" + planSlug, { replace: true });
  };

  const handleResetForm = () => {
    // Đặt lại loading và isPlanCreated về false, nhưng giữ lại giá trị các trường đã nhập
    setLoading(false);   // Trạng thái không còn loading
    setIsPlanCreated(false);  // Quay lại trạng thái trước khi tạo plan
  };

  return (
    <Container className={styles.mainCreateContainer}>
      <Box className={styles.boxWrapper}>
        {!loading && !isPlanCreated && (<>
          <Box elevation={3} className={styles.paperContent}>
            <Typography className={styles.paperTitle}>
              Welcome to the Ignition
            </Typography>
            <Typography className={styles.paperBodyContent}>
              This form will help you create a new project plan. Please fill out the details below about what you want from the project and the role you want the planner to take on. Select the language you prefer, and then click "Create" to generate your plan.
            </Typography>
          </Box>

          <Box elevation={3} className={styles.paperContent}>
            <Box className={styles.boxInputContent}>
              <Box display="flex" alignItems="center" justifyContent='space-between'>
                <Typography className={styles.titleInput}>
                  What do you want out from this project?
                </Typography>
                <Tooltip title={<>
                  <strong>What is this field?</strong><br />
                  This field is for describing your project. Please provide as much detail as possible to help the AI understand your requirements.<br />
                  <strong>What should you include?</strong><br />
                  - Brief description of the project.<br />
                  - Key objectives and goals.<br />
                  - Any specific tasks or milestones.<br />
                  The more detailed you are, the better the generated plan will be.
                </>}>
                  <IconButton>
                    <Iconify icon="octicon:info-16" width={16} height={16} color="red" />
                  </IconButton>
                </Tooltip>
              </Box>
              <TextAreaBase
                id="prompt"
                value={promptInput}
                handleChange={setPromptInput}
                minRows={3}
                multiline
                placeholder=""
                errorText={error && !promptInput ? error : ''}
                required
              />
            </Box>

            <Box className={styles.boxInputContent}>
              <Box display="flex" alignItems="center" justifyContent='space-between'>
                <Typography className={styles.titleInput}>
                  What role do you want the planner to take on?
                </Typography>
                <Tooltip title={<>
                  <strong>What is this field?</strong><br />
                  Define the role you want the planner (AI) to take on in this project.<br />
                  <strong>What should you include?</strong><br />
                  - Specify if the AI should focus on project management, creative tasks, technical aspects, or another role.<br />
                  This helps the AI understand how to approach the project and what responsibilities to take on.
                </>}>
                  <IconButton>
                    <Iconify icon="octicon:info-16" width={16} height={16} color="red" />
                  </IconButton>
                </Tooltip>
              </Box>
              <TextAreaBase
                id="role"
                value={plannerRole}
                handleChange={setPlannerRole}
                minRows={3}
                multiline
                placeholder=""
                errorText={error && !plannerRole ? error : ''}
                required
              />
            </Box>

            <Box className={styles.boxInputContent}>
              <Box display="flex" alignItems="center" justifyContent='space-between'>
                <Typography className={styles.titleInput}>
                  What language do you want the planner to take on?
                </Typography>
                <Tooltip title={<>
                  <strong>What is this field?</strong><br />
                  Select the language you want the AI to use when generating the plan.<br />
                  <strong>What should you include?</strong><br />
                  - Choose a spoken language (e.g., English, Vietnamese, Japanese) for the plan content.<br />
                </>}>
                  <IconButton>
                    <Iconify icon="octicon:info-16" width={16} height={16} color="red" />
                  </IconButton>
                </Tooltip>
              </Box>
              <InputSelectBase
                id="language"
                labelText=""
                value={language}
                handleChange={(keyword, value) => setLanguage(value)}
                options={MAIN_LANGUES_OPTION}
                keyword="language"
                placeholder="Select a language"
                errorText={error && !language ? error : ''}
              />
            </Box>

            <Button variant="contained" onClick={handleCreate} fullWidth className={styles.genPlanBtn}>
              <span>
                <Iconify icon="mingcute:ai-line" width={20} height={20} color='white' />
              </span>
              Generate
            </Button>
          </Box>
        </>)}

        {(loading || isPlanCreated) && (
          <Box className={styles.loadingBox}>
            {loading && (
              <Box sx={{ height: '84px' }}>
                <Typography align="center" className={styles.titleGenerating}>
                  Generating your plan...
                </Typography>
                <Box sx={{ width: '350px', color: mainYellowColor }}>
                  <LinearProgress color="inherit" />
                </Box>
              </Box>
            )}

            {isPlanCreated && (
              <Box sx={{ height: '84px' }}>
                <Typography align="center" className={styles.titleGenerating}>
                  Plan has been created! Do you want to go to the detail page?
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                  <Button className={styles.gotoDetailPageBtn} onClick={handleNavigateToPlan}>Goto Detail</Button>
                  <Button className={styles.reRunGenBtn} onClick={handleResetForm}>Regenerate</Button>
                </Box>
              </Box>
            )}
            <Box>
              <SnakeGameLoadingComponent fromCreatePlan={true} />
            </Box>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default CreatePlan;
