/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { Skeleton, Chip } from '@mui/material';
import { fetchOtherAccountInfo } from './services';

// 5. TODO for NAV
// sửa giúp anh giao diện này sao cho giống với giao diện của màn profile nhé
// Đây là màn OtherProfile nên chỉ cần xem các thông tin của user trong vòng kết nối của mình là được
// trước mắt thì chỉ cần sửa sao cho nó giống giao diện đã

const OtherProfile = () => {
  const { param } = useParams();

  const [mail, setMail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [desc, setDesc] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [occupation, setOccupation] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(true);
  const [skills, setSkills] = useState([]);
  const [skillsLoading, ] = useState(false);

  useEffect(() => {
    const loadAccountInfo = async () => {
      try {
        const data = await fetchOtherAccountInfo(param);
        setMail(data?.email);
        setFirstName(data?.first_name);
        setLastName(data?.last_name);
        setDesc(data.description);
        setSelectedImage(data?.avatar);
        setOccupation(data?.occupation);
        setAddress(data?.address);
        setPhoneNumber(data?.phone_number);
        setSkills(data?.skills);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    loadAccountInfo();
  }, [param]);

  return (
    <Container className="pt-6" fluid style={{ minHeight: 'calc(100vh - 61px)' }}>
      <Row>
        <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
          <Card className="card-profile shadow">
            <Row className="justify-content-center">
              <Col className="order-lg-2" lg="3">
                <div className="card-profile-image">
                  <div>
                    {loading ? (
                      <Skeleton variant="circular" width={150} height={150} />
                    ) : selectedImage ? (
                      <img
                        src={selectedImage}
                        alt="User Avatar"
                        className="rounded-circle"
                      />
                    ) : (
                      <img
                        alt="..."
                        className="rounded-circle"
                        src={require("../../assets/img/theme/default.png")}
                      />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <CardBody className="pt-0 pt-md-4">
                <Row>
                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center mt-md-6">
                      <div>
                        <span className="heading">22</span>
                        <span className="description">Friends</span>
                      </div>
                      <div>
                        <span className="heading">89</span>
                        <span className="description">Plans</span>
                      </div>
                    </div>
                  </div>
                </Row>
              <div className="text-center">
                {loading ? (
                  <>
                    <Skeleton variant="text" width="60%" />
                    <Skeleton variant="text" width="40%" />
                  </>
                ) : (
                  <>
                    <h3>{firstName} {lastName}
                      </h3>
                      <div className="h5 font-weight-300">
                        <i className="ni location_pin mr-2" />
                        {address}
                      </div>
                      <div className="h5 mt-4">
                        <i className="ni business_briefcase-24 mr-2" />
                        {occupation}
                      </div>
                      <hr className="my-4" />
                      <p>{desc}</p>
                  </>
                )}
              </div>
            </CardBody>
          </Card>
          <Card className="bg-secondary shadow mt-4">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h3 className="mb-0">Skills</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="pl-lg-4">
                  <Row>
                    <Col>
                      {skillsLoading ? (
                        <>
                          <Skeleton variant="rectangular" width="100%" height={30} className="mb-2" />
                          <Skeleton variant="rectangular" width="100%" height={30} className="mb-2" />
                          <Skeleton variant="rectangular" width="100%" height={30} className="mb-2" />
                        </>
                      ) : (
                        skills.length > 0 ? (
                          skills.map((skill) => (
                            <Chip
                              key={skill.id}
                              label={skill.name}
                              className="m-1"
                            />
                          ))
                        ) : (
                          <p>No skills available</p>
                        )
                      )}
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
        </Col>
        <Col className="order-xl-1" xl="8">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">User information</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Form>
                <h6 className="heading-small text-muted mb-4">
                  User information
                </h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-email"
                        >
                          Email address
                        </label>
                        {loading ? (
                          <Skeleton variant="rectangular" height={40} />
                        ) : (
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="jesse@example.com"
                            type="email"
                            value={mail}
                            disabled={true}
                          />
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          First name
                        </label>
                        {loading ? (
                          <Skeleton variant="rectangular" height={40} />
                        ) : (
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            placeholder="First name"
                            type="text"
                            disabled={true}
                            value={firstName}
                          />
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-last-name"
                        >
                          Last name
                        </label>
                        {loading ? (
                          <Skeleton variant="rectangular" height={40} />
                        ) : (
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            placeholder="Last name"
                            value={lastName}
                            type="text"
                            disabled={true}
                          />
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <hr className="my-4" />
                <h6 className="heading-small text-muted mb-4">
                  Contact information
                </h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Address
                        </label>
                        {loading ? (
                          <Skeleton variant="rectangular" height={40} />
                        ) : (
                          <Input
                            className="form-control-alternative"
                            id="input-address"
                            placeholder="Address"
                            type="text"
                            disabled={true}
                            value={address}
                          />
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-last-name"
                        >
                          Phone Number
                        </label>
                        {loading ? (
                          <Skeleton variant="rectangular" height={40} />
                        ) : (
                          <Input
                            className="form-control-alternative"
                            id="input-phone-number"
                            placeholder="12343567890"
                            value={phoneNumber}
                            type="text"
                            disabled={true}
                          />
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <hr className="my-4" />
                <h6 className="heading-small text-muted mb-4">About me</h6>
                <div className="pl-lg-4">
                <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          Occupation
                        </label>
                        {loading ? (
                          <Skeleton variant="rectangular" height={40} />
                        ) : (
                          <Input
                            className="form-control-alternative"
                            id="input-occupation"
                            placeholder="Occupation"
                            type="text"
                            disabled={true}
                            value={occupation}
                          />
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <label>About Me</label>
                    {loading ? (
                      <Skeleton variant="rectangular" height={100} />
                    ) : (
                      <Input
                        className="form-control-alternative"
                        placeholder="A few words about you ..."
                        rows="4"
                        type="textarea"
                        value={desc}
                        disabled={true}
                      />
                    )}
                  </FormGroup>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default OtherProfile;
