import React, { useEffect, useState } from 'react';
import Header from '../../components/Landing/Header';
import Hero from '../../components/Landing/Hero';
import About from '../../components/Landing/About';
import Features from '../../components/Landing/Features';
import Services from '../../components/Landing/Services';
import Gallery from '../../components/Landing/Gallery';
import Testimonials from '../../components/Landing/Testimonials';
import Contact from '../../components/Landing/Contact';
import Footer from '../../components/Landing/Footer';
import '../../views/main/styles.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

const App = () => {
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const sections = document.querySelectorAll('section');
    const options = {
      root: null,
      threshold: 1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    }, options);

    sections.forEach(section => observer.observe(section));

    return () => {
      sections.forEach(section => observer.unobserve(section));
    };
  }, []);

  return (
    <div>
      <Header activeSection={activeSection} />
      <Hero />
      <About />
      <Features />
      <Services />
      <Gallery />
      <Testimonials />
      <Contact />
      <Footer />
    </div>
  );
};

export default App;
