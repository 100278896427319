/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from 'react';
import { Card, CardContent, Box, Avatar, Tooltip, IconButton, Badge } from '@mui/material';
import dayjs from 'dayjs';
import SubtaskComponent from './SubtaskComponent';
import NoteDialogComponent from './noteDialogComponent';
import ConfirmDialogComponent from '../Dialog/confirm';
import AssignComponentComponent from './assignComponent';
import ActionComponent from './actionComponent';
import CommentDialogComponent from './commentDialogComponent';
import DateRangeDialogComponent from './dateRangeDialogComponent';
import AddSubtaskDialogComponent from './addSubtaskDialogComponent';
import EditableLabel from 'react-inline-editing';
import {
  updateTask, deleteTask,
  getComments, saveComment, updateComment, deleteComment,
  saveNotes, deleteNote,
  addSubTask
} from './services';
import { successSnackbar, errorSnackbar } from 'components/Snackbar/index';
import Iconify from 'components/Iconify/index';
import { btnPrimaryColor, mainYellowColor } from 'helpers/constants';
import { formatComments } from 'helpers/functions';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';

const DEFAULTCOMMENTTYPE = 1;

const TaskComponent = ({
  task,
  onDelete,
  setSelectedUsers,
  users,
  tasks,
  setTasks,
  mileStoneId,
  taskIndex
}) => {
  const [comments, setComments] = useState(task.comments || []);
  const [noteDialogOpen, setNoteDialogOpen] = useState(false);
  const [userDialogOpen, setUserDialogOpen] = useState(false);
  const [dateRangeDialogOpen, setDateRangeDialogOpen] = useState(false);
  const [commentDialogOpen, setCommentDialogOpen] = useState(false);
  const [subtaskDialogOpen, setSubtaskDialogOpen] = useState(false);
  const [subtasks, setSubtasks] = useState(task.subtasks || []);
  const [note, setNote] = useState('');
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmNoteDeleteOpen, setConfirmNoteDeleteOpen] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [assignedUsers, setAssignedUsers] = useState(task.assignees || []);
  const [taskNotes, setTaskNotes] = useState(task.memos || []);
  const [localTask, setLocalTask] = useState(task);
  const user = useSelector((state) => state.user);

  // Related to SubTask feature
  const handleAddSubtask = useCallback(async (task, subtaskName) => {
    try {
      const newSubtask = await addSubTask(task, subtaskName);
      setSubtasks((prevSubtasks) => [...prevSubtasks, newSubtask.data]);
      successSnackbar('Subtask added successfully!');
    } catch (error) {
      console.error('Error adding subtask:', error.message);
      errorSnackbar('Failed to add subtask.');
    }
  }, []);

  // Related to Comments feature
  const fetchComments = useCallback(async () => {
    try {
      const data = await getComments(task.id, DEFAULTCOMMENTTYPE);
      if (Array.isArray(data)) {
        const formattedComments = formatComments(data);
        setComments(formattedComments);
      } else {
        setComments([]);
      }
    } catch (error) {
      console.error('Error fetching comments:', error.message);
      setComments([]);
    }
  }, [task.id]);

  const handleCommentDialogToggle = useCallback(() => {
    setCommentDialogOpen(prev => !prev);
    if (!commentDialogOpen) {
      fetchComments();
    }
  }, [commentDialogOpen, fetchComments]);

  const handleCommentSubmit = useCallback(async (data) => {
    try {
      const newComment = await saveComment(task.id, data.text, DEFAULTCOMMENTTYPE);
      const formattedComment = {
        ...newComment,
        created_at: dayjs(new Date()).format('HH:mm DD/MM'),
        avatarUrl: user.avatar,
        fullName: `${user.first_name} ${user.last_name}`,
        comId: newComment.id,
        text: newComment.content,
      };
      console.log('Submit comment:: ', formattedComment);

      setComments((prevComments) => [...prevComments, formattedComment]);
      successSnackbar('Comment added successfully!');
    } catch (error) {
      console.error('Error submitting comment:', error.message);
      errorSnackbar('Failed to add comment.');
    }
  }, [task.id, user]);

  const handleCommentUpdate = useCallback(async (commentId, updatedText) => {
    try {
      await updateComment(commentId, updatedText, DEFAULTCOMMENTTYPE);
      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment.comId === commentId
            ? { ...comment, text: updatedText, created_at: dayjs(new Date()).format('HH:mm DD/MM') }
            : comment
        )
      );
      successSnackbar('Comment updated successfully!');
    } catch (error) {
      console.error('Error updating comment:', error.message);
      errorSnackbar('Failed to update comment.');
    }
  }, []);

  const handleCommentDelete = useCallback(async (commentId) => {
    try {
      await deleteComment(commentId);
      setComments(prevComments => prevComments.filter(comment => comment.comId !== commentId));
      successSnackbar('Comment deleted successfully!');
    } catch (error) {
      console.error('Error deleting comment:', error.message);
      errorSnackbar('Failed to delete comment.');
    }
  }, []);

  // Related to Notes feature
  const handleNoteDialogOpen = useCallback(() => {
    setNoteDialogOpen(true);
  }, []);

  const handleNoteDialogClose = useCallback(() => {
    setNoteDialogOpen(false);
  }, []);

  const handleSaveNote = useCallback(async (newNote) => {
    if (newNote.content.trim()) {
      try {
        const savedNote = await saveNotes(task.id, newNote.content, newNote.type);
        const formattedNote = {
          ...savedNote,
          user: newNote.user,
          created_at: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
        };
        setTaskNotes((prevNotes) => [...prevNotes, formattedNote]);
        setNote('');
        successSnackbar('Note added successfully!');
      } catch (error) {
        console.error('Error saving note:', error.message);
        errorSnackbar('Failed to save note.');
      }
    }
  }, [note, task.id, user]);

  const handleDeleteNote = useCallback(async () => {
    if (noteToDelete) {
      try {
        await deleteNote(noteToDelete);
        setTaskNotes((prevNotes) => prevNotes.filter((n) => n.id !== noteToDelete));
        successSnackbar('Note deleted successfully!');
      } catch (error) {
        console.error('Error deleting note:', error.message);
        errorSnackbar('Failed to delete note.');
      } finally {
        setConfirmNoteDeleteOpen(false);
        setNoteToDelete(null);
      }
    }
  }, [noteToDelete]);

  const confirmDeleteNote = (noteId) => {
    setNoteToDelete(noteId);
    setConfirmNoteDeleteOpen(true);
  };

  // Related to Assign feature
  const handleUserSelection = useCallback((selectedUserIds) => {
    const newUserSelections = selectedUserIds.map(userId => users.find(user => user.id === userId));
    setSelectedUsers(newUserSelections);
    setAssignedUsers(newUserSelections);
    setUserDialogOpen(false);
  }, [users, setSelectedUsers]);

  // Related to set Due Date feature
  const handleDateRangeSave = async ({ startDate, endDate }) => {
    try {
      const start = startDate ? dayjs(startDate) : null;
      const end = endDate ? dayjs(endDate) : null;
      if (start && end && end.isBefore(start)) {
        errorSnackbar('End date cannot be before start date.');
        return;
      }
      const formattedStartDate = start ? start.format('YYYY-MM-DD') : null;
      const formattedEndDate = end ? end.format('YYYY-MM-DD') : null;
      const updatedTask = { ...localTask, start_date: formattedStartDate, end_date: formattedEndDate };
      setLocalTask(updatedTask);
      await updateTask(updatedTask);
      successSnackbar('Task dates updated successfully!');
    } catch (error) {
      console.error('Error updating task dates:', error.message);
      errorSnackbar('Failed to update task dates.');
    } finally {
      setDateRangeDialogOpen(false);
    }
  };

  const handleTaskNameChange = useCallback(async (newName) => {
    try {
      await updateTask({ ...task, name: newName });
      successSnackbar('Task name updated successfully!');
    } catch (error) {
      console.error('Error updating task name:', error.message);
      errorSnackbar('Failed to update task name.');
    }
  }, [task]);

  const handleFocusOut = useCallback((text) => {
    if (text !== task.name) {
      handleTaskNameChange(text);
    }
  }, [handleTaskNameChange]);

  const handleDelete = useCallback(async () => {
    try {
      await deleteTask(task.slug);
      onDelete(task.id);
      successSnackbar('Task deleted successfully!');
    } catch (error) {
      console.error('Error deleting task:', error);
      errorSnackbar('Error deleting task: ' + error.message);
    }
    setConfirmDialogOpen(false);
  }, [onDelete, task.id]);

  return (
    <Card className={styles.card} variant="none">
      <CardContent className={styles.cardContentTop}>
        <Box sx={{ flexGrow: 1 }}>
          <Box className={styles.milestoneHeader}>
            <Iconify icon="jam:task-list-f" width={30} height={30} color={mainYellowColor} />
            <EditableLabel
              text={task.name}
              labelClassName={styles.taskNameLabel}
              inputClassName={styles.taskNameInput}
              labelFontWeight="bold"
              inputFontWeight="bold"
              onFocusOut={handleFocusOut}
              labelPlaceHolder="Enter task name"
              inputWidth="100%"
            />
          </Box>
          <Box className={styles.boxTaskActionWrapper}>
            <Box className={styles.boxTaskAction}>
              {assignedUsers.map((user) => (
                <Tooltip key={user.id} title={`${user.first_name}`}>
                  <Avatar src={user.avatar} alt={user.first_name} sx={{ width: 24, height: 24, ml: -0.5 }} />
                </Tooltip>
              ))}
            </Box>
            {comments.length > 0 && (
              <Box className={styles.boxTaskAction}>
                <IconButton onClick={handleCommentDialogToggle}>
                  <Badge badgeContent={comments.length} color="error">
                    <Iconify icon="la:comment-solid" width={30} height={30} color={btnPrimaryColor} />
                  </Badge>
                </IconButton>
              </Box>
            )}
            {taskNotes.length > 0 && (
              <Box className={styles.boxTaskAction}>
                <IconButton onClick={handleNoteDialogOpen}>
                  <Badge badgeContent={taskNotes.length} color="error">
                    <Iconify icon="fluent:note-48-regular" width={30} height={30} color={btnPrimaryColor} />
                  </Badge>
                </IconButton>
              </Box>
            )}
            {(localTask.start_date || localTask.end_date) && (
              <Box className={styles.boxScheduleTime}>
                <Box sx={{ mr: '3px' }}>
                  <Iconify icon="carbon:calendar" width={24} height={24} color="white" />
                </Box>
                {localTask.start_date && (<Box>{dayjs(localTask.start_date).format('D/M/YY')}</Box>)}
                {localTask.start_date && localTask.end_date && (<Box sx={{ mx: 0.1 }}>~</Box>)}
                {localTask.end_date && (<Box>{dayjs(localTask.end_date).format('D/M/YY')}</Box>)}
              </Box>
            )}
          </Box>
        </Box>
        <ActionComponent
          handleCommentToggle={handleCommentDialogToggle}
          setUserDialogOpen={setUserDialogOpen}
          setNoteDialogOpen={setNoteDialogOpen}
          setConfirmDialogOpen={setConfirmDialogOpen}
          setDateRangeDialogOpen={setDateRangeDialogOpen}
          handleAddSubtask={() => setSubtaskDialogOpen(true)}
          tasks={tasks}
          setTasks={setTasks}
          mileStoneId={mileStoneId}
          taskIndex={taskIndex}
        />
      </CardContent>
      <CardContent className={styles.cardContentBottom}>
        <SubtaskComponent
          subtasks={subtasks}
          setSubtasks={setSubtasks}
          task={task}
        />
      </CardContent>
      <CommentDialogComponent
        open={commentDialogOpen}
        onClose={handleCommentDialogToggle}
        comments={comments}
        handleCommentSubmit={handleCommentSubmit}
        handleCommentUpdate={handleCommentUpdate}
        handleCommentDelete={handleCommentDelete}
      />
      <NoteDialogComponent
        open={noteDialogOpen}
        notes={taskNotes}
        note={note}
        onClose={handleNoteDialogClose}
        onSave={handleSaveNote}
        setNote={setNote}
        handleDeleteNote={confirmDeleteNote}
      />
      <AddSubtaskDialogComponent
        open={subtaskDialogOpen}
        onClose={() => setSubtaskDialogOpen(false)}
        task={localTask}
        handleAddSubtask={handleAddSubtask}
      />
      <AssignComponentComponent
        open={userDialogOpen}
        users={users}
        selectedUserIds={assignedUsers.map(user => user.id)}
        onClose={() => setUserDialogOpen(false)}
        onUsersSelect={handleUserSelection}
        task={task}
      />
      <DateRangeDialogComponent
        open={dateRangeDialogOpen}
        onClose={() => setDateRangeDialogOpen(false)}
        onSave={handleDateRangeSave}
        initialStartDate={localTask.start_date}
        initialEndDate={localTask.end_date}
      />
      <ConfirmDialogComponent
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={handleDelete}
        title="Confirm Delete"
        description="Are you sure you want to delete this task?"
      />
      <ConfirmDialogComponent
        open={confirmNoteDeleteOpen}
        onClose={() => setConfirmNoteDeleteOpen(false)}
        onConfirm={handleDeleteNote}
        title="Confirm Delete"
        description="Are you sure you want to delete this note?"
      />
    </Card>
  );
};

export default TaskComponent;
