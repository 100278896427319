import React from 'react';
import { List, ListItem, Checkbox, ListItemText, IconButton, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import EditableLabel from 'react-inline-editing';
import { updateSubTask, deleteSubTask, updateSubTaskOrder } from './services';
import { successSnackbar, errorSnackbar } from 'components/Snackbar/index';
import { DONE_STATUS, TODO_STATUS, btnPrimaryColor, mainRedColor } from "helpers/constants";
import Iconify from 'components/Iconify/index';
import styles from './styles.module.scss';

const StyledListItem = styled(ListItem)(({ theme, checked }) => ({
  backgroundColor: checked ? theme.palette.action.selected : 'inherit',
  textDecoration: checked ? 'line-through' : 'none',
  color: checked ? theme.palette.text.disabled : theme.palette.text.primary,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  transition: 'background-color 0.3s ease',
}));

const SubtaskComponent = ({ subtasks, setSubtasks, task }) => {
  const handleToggleSubtask = async (subtask, subtaskIndex) => {
    const isDone = subtask.status === DONE_STATUS;
    const updatedSubtask = {
      ...subtask,
      status: isDone ? TODO_STATUS : DONE_STATUS,
      progress: isDone ? 0 : 100,
    };
    try {
      await updateSubTask(updatedSubtask);
      const updatedSubtasks = subtasks.map((st, index) =>
        index === subtaskIndex ? updatedSubtask : st
      );
      setSubtasks(updatedSubtasks);
      successSnackbar('SubTask status changed successfully!');
    } catch (error) {
      console.error('Error updating subtask:', error.message);
      errorSnackbar('Failed to update subtask status.');
    }
  };

  const handleSubtaskNameChange = async (subtask, newName) => {
    const updatedSubtask = {
      ...subtask,
      name: newName,
    };
    try {
      await updateSubTask(updatedSubtask);
      const updatedSubtasks = subtasks.map((st) =>
        st.id === subtask.id ? updatedSubtask : st
      );
      setSubtasks(updatedSubtasks);
      successSnackbar('SubTask name updated successfully!');
    } catch (error) {
      console.error('Error updating subtask name:', error.message);
      errorSnackbar('Failed to update subtask name.');
    }
  };

  const handleDeleteSubtask = async (subtaskIndex, subtaskSlug) => {
    try {
      await deleteSubTask(subtaskSlug);
      const updatedSubtasks = subtasks.filter((_, index) => index !== subtaskIndex);
      setSubtasks(updatedSubtasks);
      successSnackbar('SubTask deleted successfully!');
    } catch (error) {
      console.error('Error deleting subtask:', error.message);
      errorSnackbar('Failed to delete subtask.');
    }
  };

  const moveSubtask = async (fromIndex, toIndex) => {
    const updatedSubtasks = [...subtasks];
    const [movedSubtask] = updatedSubtasks.splice(fromIndex, 1);
    updatedSubtasks.splice(toIndex, 0, movedSubtask);
    const reorderedSubtasks = updatedSubtasks.map((subtask, index) => ({
      ...subtask,
      order: index + 1,
    }));
    setSubtasks(reorderedSubtasks);

    try {
      await updateSubTaskOrder(task.slug, reorderedSubtasks);
      successSnackbar('SubTask order updated successfully!');
    } catch (error) {
      console.error('Error updating subtask order:', error.message);
      errorSnackbar('Failed to update subtask order.');
    }
  };

  const handleFocusOut = (subtask, newName) => {
    if (newName !== subtask.name) {
      handleSubtaskNameChange(subtask, newName);
    }
  };

  return (
    <List>
      {subtasks.map((subtask, subtaskIndex) => (
        <StyledListItem
          key={subtask.id}
          sx={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0 }}
          checked={subtask.status === DONE_STATUS}>
          <Checkbox
            checked={subtask.status === DONE_STATUS}
            onChange={() => handleToggleSubtask(subtask, subtaskIndex)}
            onClick={(e) => e.stopPropagation()}
          />
          <ListItemText>
            <EditableLabel
              text={subtask.name}
              labelClassName={styles.subtaskNameLabel}
              inputClassName={styles.subtaskNameInput}
              onFocusOut={(newName) => handleFocusOut(subtask, newName)}
              labelPlaceHolder="Enter subtask name"
              inputMaxLength={500}
              inputWidth="100%"
            />
          </ListItemText>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {subtaskIndex > 0 && (
              <IconButton
                onClick={() => moveSubtask(subtaskIndex, subtaskIndex - 1)}
                size="small"
                edge="end"
                sx={{ marginLeft: 1 }}>
                <Iconify icon="ph:arrow-circle-up-bold" width={30} height={30} color={btnPrimaryColor} />
              </IconButton>
            )}
            {subtaskIndex < subtasks.length - 1 && (
              <IconButton
                onClick={() => moveSubtask(subtaskIndex, subtaskIndex + 1)}
                size="small"
                edge="end"
                sx={{ marginLeft: 1 }}>
                <Iconify icon="ph:arrow-circle-down-bold" width={30} height={30} color={btnPrimaryColor} />
              </IconButton>
            )}
            <IconButton
              onClick={() => handleDeleteSubtask(subtaskIndex, subtask.slug)}
              size="small"
              edge="end"
              sx={{ marginLeft: "0px" }}>
              <Iconify icon="ph:x-circle-bold" width={30} height={30} color={mainRedColor} />
            </IconButton>
          </Box>
        </StyledListItem>
      ))}
    </List>
  );
};

export default SubtaskComponent;
