/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Paper, Typography, Avatar, Box, Grid, Button } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { successSnackbar, errorSnackbar } from 'components/Snackbar/index';
import { getHeaders } from "helpers/functions";
import { APIURL } from "helpers/constants";

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const handleRead = async (id) => {
    try {
      const response = await axios.put(`${APIURL}/api/notifications/update/${id}`,
        null,
        { headers: getHeaders() }
      );

      if (response.status === 200) {
        getNotifications();
        successSnackbar("Updated successfully!");
      } else {
        errorSnackbar("Failed to update notification!");
      }
      return
    } catch (error) {
      throw new Error('Error marking notification as read: ' + error.message);
    }
  };
  const handleReadAll = async () => {
    try {
      const response = await axios.put(`${APIURL}/api/notifications/update-all`,
        null,
        { headers: getHeaders() }
      );

      if (response.status === 200) {
        getNotifications();
        successSnackbar("All notifications updated successfully!");
      } else {
        errorSnackbar("Failed to update all notifications!");
      }
    } catch (error) {
      throw new Error('Error marking all notifications as read: ' + error.message);
    }
  }

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${APIURL}/api/notifications/delete/${id}`,
        { headers: getHeaders() }
      );
      if (response.status === 200) {
        getNotifications();
        successSnackbar("All notifications deleted successfully!");
      } else {
        errorSnackbar("Failed to delete all notifications!");
      }
    } catch (error) {
      throw new Error('Error deleting all notifications: ' + error.message);
    }
  }

  const getNotifications = async () => {
    try {
      const response = await axios.get(`${APIURL}/api/notifications`, { headers: getHeaders() });
      setNotifications(response.data);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching comments: ' + error.message);
    }
  }

  const renderButtonRead = (notification) => {
    if (!notification.is_read) {
      return (
        <Button variant="contained" size="small" onClick={() => handleRead(notification.id)}>
          Read
        </Button>
      )
    }
  }

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <Box sx={{ flexGrow: 1, maxWidth: 752, margin: 'auto' }}>
      <Typography variant="h6" component="div" sx={{ margin: 2 }}>
        Notifications
      </Typography>
      <Button
        variant="contained"
        size="small"
        sx={{ marginRight: 1 }}
        onClick={() => handleReadAll()}>
        Mark All As Read
      </Button>
      <Grid container spacing={2}>
        {notifications?.map((notification, index) => (
          <Grid item xs={12} key={index}>
            <Paper elevation={notification?.is_read ? 1 : 3}>
              <Avatar sx={{ marginRight: 2 }}>
                <NotificationsIcon />
              </Avatar>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="body1" component="div">
                  {notification.message || 'No message available'}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {notification.created_at
                    ? new Date(notification.created_at).toLocaleString()
                    : 'No date available'}
                </Typography>
              </Box>
              <Box>
                {renderButtonRead(notification)}
                <Button
                  variant="outlined"
                  size="small"
                  href={`/accept/${notification.id}`}>
                  Accept
                </Button>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ marginRight: 1 }}
                  onClick={() => handleDelete(notification.id)}>
                  <i className="ni ni-fat-delete" style={{ fontSize: '26px' }} />
                </Button>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Notifications;
