import Home from "views/home/index.js";
import ContactDirectory from "views/users/ContactDirectory.js";
import Icons from "views/main/Icons.js";
import CalendarComponent from "views/calendar/index.js";
import CreatePlan from "views/plan/create.js";
import PlanDetail from "views/plan/detail.js";
import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";
import ForgotPassword from "views/auth/ForgotPassword.js";
import Activate from "views/auth/Activate.js";
import Reset from "views/auth/Reset.js";
import GoogleAuthHandle from "views/auth/GoogleAuthHandle";
import Profile from "views/users/profile/index.js";
import OtherProfile from "views/users/OtherProfile.js";
import TodoComponent from "views/todo/index.js";
import Notifications from "views/notifications/index.js";

import { COMMON_PAGE_KEY, ADMIN_PAGE_KEY } from "helpers/constants";

var routes = [
  {
    path: "/",
    layout: ADMIN_PAGE_KEY,
    name: "HOME",
    component: <Home />,
    private: true,
  },
  {
    path: "/plan/create",
    layout: ADMIN_PAGE_KEY,
    name: "CREATE_A_PLAN",
    component: <CreatePlan />,
    private: true,
  },
  {
    path: "/plan/:param",
    layout: ADMIN_PAGE_KEY,
    name: "PLAN_INFOMATION",
    component: <PlanDetail />,
    private: true,
  },
  {
    path: "/follows",
    layout: ADMIN_PAGE_KEY,
    name: "CONTACT_DIRECTORY",
    component: <ContactDirectory />,
    private: true,
  },
  {
    path: "/profile",
    layout: ADMIN_PAGE_KEY,
    name: "CURRENT_USER_PROFILE",
    component: <Profile />,
    private: true,
  },
  {
    path: "/other/profile/:param",
    layout: ADMIN_PAGE_KEY,
    name: "OTHER_USER_PROFILE",
    component: <OtherProfile />,
    private: true,
  },
  {
    path: "/icons",
    layout: ADMIN_PAGE_KEY,
    name: "ICONS",
    component: <Icons />,
    private: true,
  },
  {
    path: "/meals/idea",
    layout: ADMIN_PAGE_KEY,
    name: "MEALS_IDEA",
    component: <Icons />,
    private: true,
  },
  {
    path: "/my/tasks/calendar",
    layout: ADMIN_PAGE_KEY,
    name: "MY_TASKS_CALENDAR",
    component: <CalendarComponent />,
    private: true,
  },
  {
    path: "/my/tasks/table",
    layout: ADMIN_PAGE_KEY,
    name: "MY_TASKS_TABLE",
    component: <TodoComponent />,
    private: true,
  },
  {
    path: "/notifications",
    layout: ADMIN_PAGE_KEY,
    name: "MY_NOTIFICATIONS",
    component: <Notifications />,
    private: true,
  },
  // No Auth page
  {
    path: "/login",
    layout: COMMON_PAGE_KEY,
    name: "LOGIN",
    component: <Login />,
    private: false,
  },
  {
    path: "/register",
    layout: COMMON_PAGE_KEY,
    name: "REGISTER",
    component: <Register />,
    private: false,
  },
  {
    path: "/activate/:param1/:param2",
    layout: COMMON_PAGE_KEY,
    name: "ACTIVATE",
    component: <Activate />,
    private: false,
  },
  {
    path: "/reset/:uid/:token",
    layout: COMMON_PAGE_KEY,
    name: "RESET_PASSWORD",
    component: <Reset />,
    private: false,
  },
  {
    path: "/forgot-password",
    layout: COMMON_PAGE_KEY,
    name: "FORGOT_PASSWORD",
    component: <ForgotPassword />,
    private: false,
  },
  {
    path: "/google",
    layout: COMMON_PAGE_KEY,
    name: "GOOGLE_AUTH_HANDLE",
    component: <GoogleAuthHandle />,
    private: false,
  },
];

export default routes;
