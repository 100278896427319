import { Col, Nav, NavItem, NavLink } from "reactstrap";

// 2. TODO for NAV
// cho to cỡ chữ ở Footer
// cho background color tối lại
// cho chữ trắng lên

const Footer = () => {
  return (
    <footer className="footer">
      <div className="d-flex align-items-center justify-content-between">
        <Col xl="6">
          <div className="copyright text-xl-left">
            © {new Date().getFullYear()}{" "}
            <a className="ml-1"
              href="https://google.com"
              rel="noopener noreferrer"
              target="_blank">
              Ignition Team
            </a>
          </div>
        </Col>

        <Col xl="6">
          <Nav className="nav-footer justify-content-end">
            <NavItem>
              <NavLink
                href="https://www.creative-tim.com?ref=adr-admin-footer"
                rel="noopener noreferrer"
                target="_blank">
                Ignition
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                href="https://www.creative-tim.com/presentation?ref=adr-admin-footer"
                rel="noopener noreferrer"
                target="_blank">
                About Us
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                href="http://blog.creative-tim.com?ref=adr-admin-footer"
                rel="noopener noreferrer"
                target="_blank">
                Blog
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </div>
    </footer>
  );
};

export default Footer;
