/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Container, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, CircularProgress } from '@mui/material';
import { getHeaders } from "helpers/functions";
import { APIURL } from "helpers/constants";

// 4. TODO for NAV
// màn này đang bị lỗi API dường như là sai tên, xem lại bên backend và đổi tên api để lấy được các event theo api

const CalendarComponent = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  // Fetch real data from the API
  const fetchEvents = async () => {
    try {
      const response = await fetch(`${APIURL}/api/task/retrieve`, { headers: getHeaders() });
      const data = await response.json();
      const formattedEvents = data.map(task => ({
        id: task.id,
        title: task.name,
        start: task.start_date,
        end: task.end_date
      }));
      setEvents(formattedEvents);
    } catch (error) {
      console.error('Error fetching events:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleEventClick = (eventClickInfo) => {
    setSelectedEvent(eventClickInfo.event);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedEvent(null);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container className='pt-4' sx={{ minHeight: 'calc(100vh - 61px)' }} fluid>
      <Box sx={{ border: '1px solid #ccc', borderRadius: '5px', overflow: 'hidden', padding: '30px' }}>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={events}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          editable={true}
          selectable={true}
          eventClick={handleEventClick}
        />
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Event Details</DialogTitle>
        <DialogContent>
          {selectedEvent && (
            <>
              <DialogContentText>
                <strong>Title:</strong> {selectedEvent.title}
              </DialogContentText>
              <DialogContentText>
                <strong>Start:</strong> {selectedEvent.start.toISOString()}
              </DialogContentText>
              <DialogContentText>
                <strong>End:</strong> {selectedEvent.end ? selectedEvent.end.toISOString() : 'N/A'}
              </DialogContentText>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CalendarComponent;
