import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField, InputAdornment, IconButton, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CalendarToday } from '@mui/icons-material';
import ms from './styles.module.scss';
import dayjs from 'dayjs';

const borderColor = '#9E9991';
const hoverColor = '#9E9991';
const focusColor = '#9E9991';
const placeholderColor = '#888888';
const primaryFont = 'Recursive Variable';

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontFamily: primaryFont,
    borderWidth: '1px',
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderColor: hoverColor,
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: '1px',
    borderColor: borderColor,
    borderRadius: '5px',
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: focusColor,
    borderWidth: '1px',
  },
  '& .MuiInputBase-input': {
    padding: '12px 14px',
    '&::placeholder': {
      color: placeholderColor,
      opacity: 1,
    },
  },
}));

const CustomDatePicker = (props) => {
  const { values, setValues, name, size, labelName } = props;
  const [open, setOpen] = useState(false);
  const handleDateChange = (newValue) => {
    setValues && setValues((state) => ({
      ...state,
      [name]: newValue ? dayjs(newValue) : null
    }));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
      <Box className={ms.titleAdminDetail}>{labelName}</Box>
      <StyledDatePicker
        format="DD/MM/YYYY"
        inputFormat="DD/MM/YYYY"
        value={values?.[name] ? dayjs(values[name]) : null}
        onChange={handleDateChange}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        sx={{ width: "100%" }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              fullWidth
              size={size}
              onClick={() => setOpen(true)}
              InputProps={{
                ...params.InputProps,
                endAdornment: null,
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton sx={{ padding: 0 }} onClick={() => setOpen(true)}>
                      <CalendarToday sx={{ fontSize: "1.1rem" }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )
        }
        }
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;