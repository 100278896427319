import axios from 'axios';
import { getHeaders } from "helpers/functions";
import { APIURL, DONE_STATUS } from "helpers/constants";

export const fetchTasks = async () => {
  try {
    const response = await axios.get(`${APIURL}/api/retrieve/mytask`, { headers: getHeaders() });
    return response.data;
  } catch (error) {
    throw new Error('Error fetching tasks: ' + error.message);
  }
};

export const deleteTask = async (taskId) => {
  try {
    const response = await axios.delete(`${APIURL}/api/task/delete/${taskId}`, { headers: getHeaders() });
    return response.data;
  } catch (error) {
    throw new Error('Error deleting task: ' + error.message);
  }
};

export const addTask = async (taskData) => {
  try {
    const response = await axios.post(`${APIURL}/api/task/add`, taskData, { headers: getHeaders() });
    return response.data;
  } catch (error) {
    throw new Error('Error adding task: ' + error.message);
  }
};

export const toggleTaskComplete = async (taskId, status) => {
  try {
    const response = await axios.put(`${APIURL}/api/task/${taskId}/complete`,
      { completed: status === DONE_STATUS },
      { headers: getHeaders() }
    );
    return response.data;
  } catch (error) {
    throw new Error('Error updating task: ' + error.message);
  }
};

export const updateTask = async (task) => {
  try {
    const formData = new FormData();
    formData.append('name', task.name);

    if (task.start_date) {
      formData.append('start_date', task.start_date);
    }
    if (task.end_date) {
      formData.append('end_date', task.end_date);
    }

    const response = await axios.put(`${APIURL}/api/tasks/update/${task.slug}`, formData, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    throw new Error('Error updating task: ' + error.message);
  }
};