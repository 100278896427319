import { useEffect, useState } from 'react';
import { fetchPlanInfo } from '../views/plan/services';

const usePlanDetail = (param) => {
  const [planInfo, setPlanInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorMes, setErrorMes] = useState('');
  // const [error, setError] = useState(false);

  useEffect(() => {
    const fetchPlanInfoData = async () => {
      try {
        const data = await fetchPlanInfo(param);
        setPlanInfo(data.data);
      } catch (error) {
        setErrorMes(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPlanInfoData();
  }, [param]);

  return {
    planInfo,
    loading,
    errorMes,
    // error,   // Trả về state error cho plan/detail.js
  };
};

export default usePlanDetail;
