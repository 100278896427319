import React from 'react';
import { Container } from '@mui/material';
import styles from './styles.module.scss';

const Testimonials = () => (
  <section id="testimonials" className={styles.testimonialsSection}>
    <Container className="main-container" style={{ padding: '30px 0 70px 0' }}>
      <h2 className={styles.titleSection} style={{ color: 'lightgray' }}>Testimonials</h2>
      <div className={styles.testimonialsGrid}>
        <div className={styles.testimonial} data-aos="fade-up">
          <p>"AI Planner has transformed how we manage our projects. It's intuitive and highly effective!"</p>
          <p style={{ fontSize: '1rem' }}>- John Doe, CEO of TechCorp</p>
        </div>
        <div className={styles.testimonial} data-aos="fade-up" data-aos-delay="100">
          <p>"The dynamic updates feature is a game-changer. It keeps us on track even when plans change."</p>
          <p style={{ fontSize: '1rem' }}>- Jane Smith, Project Manager at Innovate Inc.</p>
        </div>
        <div className={styles.testimonial} data-aos="fade-up" data-aos-delay="200">
          <p>"The collaboration tools have made it so much easier for our team to work together."</p>
          <p style={{ fontSize: '1rem' }}>- Mark Johnson, Team Lead at DevelopIt</p>
        </div>
      </div>
      <div className={styles.testimonialsGrid} style={{ marginTop: '20px' }}>
        <div className={styles.testimonial} data-aos="fade-up">
          <p>"AI Planner has transformed how we manage our projects. It's intuitive and highly effective!"</p>
          <p style={{ fontSize: '1rem' }}>- John Doe, CEO of TechCorp</p>
        </div>
        <div className={styles.testimonial} data-aos="fade-up" data-aos-delay="100">
          <p>"The dynamic updates feature is a game-changer. It keeps us on track even when plans change."</p>
          <p style={{ fontSize: '1rem' }}>- Jane Smith, Project Manager at Innovate Inc.</p>
        </div>
        <div className={styles.testimonial} data-aos="fade-up" data-aos-delay="200">
          <p>"The collaboration tools have made it so much easier for our team to work together."</p>
          <p style={{ fontSize: '1rem' }}>- Mark Johnson, Team Lead at DevelopIt</p>
        </div>
      </div>
    </Container>
  </section>
);

export default Testimonials;
