import React from 'react';
import { Box, IconButton, Dialog, Chip, Card, CardContent, Avatar, Typography } from '@mui/material';
import ConfirmDialogComponent from '../Dialog/confirm';
import { useSelector } from 'react-redux';
import InputBase from 'components/Input/InputBase';
import dayjs from 'dayjs';
import Iconify from 'components/Iconify/index';
import styles from './styles.module.scss';
import { btnPrimaryColor, mainRedColor, mainYellowColor } from 'helpers/constants';

const NoteDialogComponent = ({
  open,
  notes,
  note,
  onClose,
  onSave,
  setNote,
  handleDeleteNote,
}) => {
  const [error, setError] = React.useState("");
  const [editingNoteId, setEditingNoteId] = React.useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [deletingNoteId, setDeletingNoteId] = React.useState(null);
  const user = useSelector((state) => state.user);

  const handleSubmit = () => {
    if (editingNoteId) {
      setEditingNoteId(null);
      setNote("");
    } else if (note.trim()) {
      const newNote = {
        content: note,
        user: {
          id: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          avatar: user.avatar,
        },
        created_at: dayjs().format('YYYY-MM-DDTHH:mm:ssZ'),
        type: 1,
      };
      onSave(newNote);
      setNote("");
      setError("");
    } else {
      setError("Please enter a note before saving.");
    }
  };

  const handleEditClick = (note) => {
    setEditingNoteId(note.id);
    setNote(note.content);
  };

  const handleDeleteClick = (noteId) => {
    setDeletingNoteId(noteId);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    handleDeleteNote(deletingNoteId);
    setConfirmDialogOpen(false);
    setDeletingNoteId(null);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Box className={styles.noteDialogContainer}>
        <Typography variant="h5" className={styles.noteTitle}>My Notes</Typography>
        <Box className={styles.noteInputContainer}>
          <InputBase
            id="note-input"
            value={note}
            placeholder="Write your note..."
            handleChange={(value) => setNote(value)}
            errorText={error}
            helperText={error}
            required
            sx={{ flexGrow: 1, marginRight: 2 }}
          />
          <IconButton
            onClick={handleSubmit}
            color="primary"
            className={styles.noteSendButton}>
            <Iconify icon='ion:send' width={32} height={32} color={mainYellowColor} />
          </IconButton>
        </Box>

        <Box className={styles.noteList}>
          {notes.length > 0 ? (
            notes.map((note) => (
              <Card key={note.id} className={styles.noteCard}>
                <CardContent sx={{ padding: '16px' }} className={styles.noteCardContent}>
                  <Typography variant="body1" className={styles.noteContent}>{note.content}</Typography>
                  <Box className={styles.noteFooter}>
                    <Avatar src={note.user.avatar} alt={note.user.first_name} sx={{ width: '24px', height: '24px' }} />
                    <Box className="d-flex">
                      <Chip label={dayjs(note.created_at).format('HH:mm DD/MM')} size="small" />
                      <Box className={styles.noteActions}>
                        <IconButton
                          onClick={() => handleEditClick(note)}
                          size="small"
                          sx={{ p: 0, pl: 1 }}>
                          <Iconify icon='tabler:edit' width={24} height={24} color={btnPrimaryColor} />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDeleteClick(note.id)}
                          size="small"
                          sx={{ p: 0 }}>
                          <Iconify icon='ph:x-circle-bold' width={24} height={24} color={mainRedColor} />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            ))
          ) : (
            <Typography className={styles.noNotesMessage}>
              No notes available.
            </Typography>
          )}
        </Box>
      </Box>
      <ConfirmDialogComponent
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={handleConfirmDelete}
        title="Confirm Delete"
        description="Are you sure you want to delete this note?"
      />
    </Dialog>
  );
};

export default NoteDialogComponent;
