import React from 'react';
import { Box, Popper, Button, ClickAwayListener } from '@mui/material';
import Iconify from 'components/Iconify/index';
import InputBase from 'components/Input/InputBase';
import CustomDatePicker from 'components/Input/CustomDatePicker';
import { mainYellowColor } from "helpers/constants";
import styles from "./styles.module.scss";

const FilterPopper = ({ id, open, anchorEl, filters, onFilterChange, onApplyFilters, onClose, setFilters }) => {
  return (
    <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start" sx={{ zIndex: 1 }}>
      <ClickAwayListener onClickAway={onClose}>
        <Box className={styles.popperFilterSection} sx={{ boxShadow: 3 }}>
          <Box className={styles.inputGroup}>
            <Box sx={{ mr: '8px' }}><Iconify icon="codicon:project" width={30} height={30} color={mainYellowColor} /></Box>
            <InputBase
              id="plan-name-input"
              value={filters.text}
              placeholder="Search by plan name..."
              handleChange={(e) => onFilterChange({ target: { name: 'text', value: e } })}
              errorText=""
              helperText=""
              required
            />
          </Box>
          <Box className={styles.inputGroup}>
            <Box sx={{ mr: '8px' }}><Iconify icon="ph:share" width={30} height={30} color={mainYellowColor} /></Box>
            <InputBase
              id="invite-user-input"
              value={filters.inviteUser}
              placeholder="Search by invite user name ..."
              handleChange={(e) => onFilterChange({ target: { name: 'inviteUser', value: e } })}
              errorText=""
              helperText=""
              required
            />
          </Box>
          <Box className={styles.inputGroup}>
            <Box sx={{ mr: '8px' }}><Iconify icon="fluent:document-text-clock-24-regular" width={30} height={30} color={mainYellowColor} /></Box>
            <CustomDatePicker
              name="createdDate"
              values={filters}
              setValues={setFilters}
              size="small"
              labelName=""
            />
          </Box>
          <Button variant="contained" onClick={onApplyFilters} className={styles.applyBtn}>
            Apply
          </Button>
        </Box>
      </ClickAwayListener>
    </Popper>
  );
};

export default FilterPopper;
