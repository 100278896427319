import { Select, MenuItem, FormControl, InputLabel, Typography, Avatar, Box } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';

const InputSelectBase = (props) => {
  const {
    labelText,
    handleChange,
    errorText,
    keyword,
    id,
    value,
    disabled = false,
    size,
    variant = "outlined",
    className,
    options = [],
    required,
    multiple = false,
    InputProps,
    defaultOption,
    isEdit,
    placeholder,
    showAvatar = false,
  } = props;

  const [selectedValue, setSelectedValue] = useState(value || '');
  const selectRef = useRef(null);
  const borderColor = '#9E9991';
  const primaryColor = '#9E9991';
  const primaryFont = 'Recursive Variable';

  useEffect(() => {
    if (isEdit) {
      setSelectedValue(value);
    }
  }, [isEdit, value]);

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    handleChange(keyword, event.target.value);
  };

  const getDefaultValue = () => {
    if (selectedValue === '' && !value && defaultOption) {
      return defaultOption.value;
    }
    return selectedValue;
  };

  return (
    <FormControl
      fullWidth
      variant={variant}
      size={size}
      className={className}
      disabled={disabled}
      error={!!errorText}
      required={required}>
      {labelText && <InputLabel sx={{ marginBottom: 0, marginTop: "5px" }} id={`${id}-label`}>{labelText}</InputLabel>}
      <Select
        labelId={`${id}-label`}
        id={id}
        value={getDefaultValue()}
        onChange={handleSelectChange}
        renderValue={selectedValue !== '' ? undefined : () => (
          <Typography sx={{
            opacity: '0.3',
            fontFamily: primaryFont,
            fontSize: '14px',
            marginTop: '1px'
          }}>{placeholder}</Typography>
        )}
        multiple={multiple}
        startAdornment={InputProps?.startAdornment}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: 200,
              width: selectRef.current ? selectRef.current.clientWidth : 'auto',
              '& .MuiMenuItem-root': {
                fontFamily: primaryFont,
              },
            },
          },
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '3.3rem',
          padding: 0,
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
            borderColor: borderColor,
            borderRadius: '5px'
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
            borderColor: primaryColor,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
            borderColor: primaryColor,
          },
        }}
        ref={selectRef}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value} disabled={option.value === defaultOption?.value}
            sx={{
              fontFamily: primaryFont,
              whiteSpace: 'normal',
              display: 'flex',
              alignItems: 'center',
            }}>
            {showAvatar && (
              <Avatar src={option.avatar} alt="Avatar" sx={{ width: 24, height: 24, mr: 1 }} />
            )}
            <Box sx={{ display: 'flex', flexDirection: 'column'}}>
              <Typography sx={{ fontFamily: primaryFont, color: '#333', fontSize: '1.125rem' }}>{option.label}</Typography>
              {option.subLabel && (
                <Typography variant="caption" color="textSecondary"
                  sx={{ fontFamily: primaryFont, color: '#333', fontSize: '1.125rem' }}>
                  {option.subLabel}
                </Typography>
              )}
            </Box>
          </MenuItem>
        ))}
      </Select>
      {errorText && (
        <Typography variant="body2" color="error" sx={{ marginTop: '5px', fontSize: '0.875rem', color: 'red' }}>
          {errorText}
        </Typography>
      )}
    </FormControl>
  );
};

export default InputSelectBase;
