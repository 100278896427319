import React from 'react';
import { Box, Card, CardContent, Typography, Skeleton, Button, CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import InputGroupComponent from './inputGroup';
import styles from '../styles.module.scss';

//--------------------------------------------------------------------------------------------------

export const ProfileComponent = ({ profile, loading, handleChange, handleSaveProfile, formErrors }) => {

  return (
    <Card sx={{ boxShadow: 3 }}>
      <Box className={styles.cardHeader}>
        <Typography className={styles.cardHeaderTitle}>My account</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveProfile}
          disabled={loading}
          className={styles.addOrSaveBtn}>
          {loading ? <CircularProgress size={24} /> : "Save"}
        </Button>
      </Box>

      <CardContent>
        <Typography className={styles.sectionHeaderTitle}>User Information</Typography>
        <Box sx={{ paddingLeft: 4 }}>
          <InputGroupComponent
            label="First Name"
            id="input-first-name"
            value={profile.firstName}
            placeholder="First name"
            handleChange={(value) => handleChange({ target: { name: 'firstName', value } })}
            errorText={formErrors.first_name ? formErrors.first_name[0] : ""}
            helperText=""
          />
          {loading && <Skeleton variant="rectangular" height={40} />}

          <InputGroupComponent
            label="Last Name"
            id="input-last-name"
            value={profile.lastName}
            placeholder="Last name"
            handleChange={(value) => handleChange({ target: { name: 'lastName', value } })}
            errorText={formErrors.last_name ? formErrors.last_name[0] : ""}
            helperText=""
          />
          {loading && <Skeleton variant="rectangular" height={40} />}

          <InputGroupComponent
            label="Address"
            id="input-address"
            value={profile.address}
            placeholder="Address"
            handleChange={(value) => handleChange({ target: { name: 'address', value } })}
            errorText={formErrors.address ? formErrors.address[0] : ""}
            helperText=""
          />
          {loading && <Skeleton variant="rectangular" height={40} />}

          <Box className={styles.dateJoinStyle}>
            <Box className={styles.profileLabelGroup}>Join Date: </Box>
            <Box className={styles.profileValueGroup}>{dayjs(profile.dateJoined).format('MMMM DD, YYYY')}</Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
