/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import axios from 'axios';
import {
  CardTitle,
  Card,
  CardBody,
  Col
} from "reactstrap";
import { useParams, useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';

const Activate = () => {
  const { param1, param2 } = useParams();
  const [respMessage, setRespMessage] = useState();
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "http://127.0.0.1:8000";

  useEffect(() => {
    handleActivate(param1, param2);
  }, [param1, param2]);

  const handleActivate = async (param1, param2) => {
    const formData = new FormData();
    formData.append('uid', param1);
    formData.append('token', param2)
    try {
      const response = await axios.post(`${apiUrl}/api/user/activate`,
        formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      if (response.data.status === 201) {
        setRespMessage(response.data.message)
        setTimeout(() => {
          navigate('/login', { replace: true });
        }, 3000);
      }
    } catch (error) {
      setRespMessage(error.response.data)
    }
  };

  return (
    <>
      <Col lg="6" md="8">
        <Card className={`bg-secondary shadow border-0 ${styles.customMessageCard}`}>
          <CardBody className="px-lg-3 py-lg-7 text-center">
            <CardTitle className="mb-4" style={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'lightgreen' }}>Success message</CardTitle>
            <div style={{ fontSize: '1.2rem', color: 'white' }}>
              {respMessage}
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Activate;
