import React, { useState } from 'react';
import { Box, Card, CardContent, Typography, Skeleton } from '@mui/material';
import InputGroupComponent from './inputGroup';
import TextAreaBase from 'components/Input/TextAreaBase';
import styles from '../styles.module.scss';

//--------------------------------------------------------------------------------------------------

export const ContactComponent = ({ profile, loading, handleChange, formErrors }) => {
  const [charCount, setCharCount] = useState(profile.desc.length || 0);
  const maxChars = 200;

  const handleDescChange = (valueOrEvent) => {
    const value = valueOrEvent.target ? valueOrEvent.target.value : valueOrEvent;
    setCharCount(value.length);
    handleChange({ target: { name: 'desc', value } });
  };

  return (
    <Card sx={{ boxShadow: 3, mt: 1 }}>
      <CardContent>
        <Typography className={styles.sectionHeaderTitle}>Contact Information</Typography>
        <Box sx={{ paddingLeft: 4 }}>
          <InputGroupComponent
            label="Email Address"
            id="input-email"
            value={profile.mail}
            placeholder="Email address"
            handleChange={(value) => handleChange({ target: { name: 'mail', value } })}
            errorText=""
            helperText=""
            disabled={true}
          />
          {loading && <Skeleton variant="rectangular" height={40} />}

          <InputGroupComponent
            label="Phone Number"
            id="input-phone-number"
            value={profile.phoneNumber}
            placeholder="Phone Number"
            handleChange={(value) => handleChange({ target: { name: 'phoneNumber', value } })}
            errorText={formErrors.phone_number ? formErrors.phone_number[0] : ""}
            helperText=""
          />
          {loading && <Skeleton variant="rectangular" height={40} />}
        </Box>

        <hr className="mt-5 mb-4" />

        <Typography className={styles.sectionHeaderTitle}>Personal Information</Typography>
        <Box sx={{ paddingLeft: 4 }}>
          <InputGroupComponent
            label="Occupation"
            id="input-occupation"
            value={profile.occupation}
            placeholder="Occupation"
            handleChange={(value) => handleChange({ target: { name: 'occupation', value } })}
            errorText={formErrors.occupation ? formErrors.occupation[0] : ""}
            helperText=""
          />
          {loading && <Skeleton variant="rectangular" height={40} />}

          <Box className={styles.profileLabelGroup}>About Me</Box>
          <TextAreaBase
            id="role"
            value={profile.desc}
            handleChange={handleDescChange}
            minRows={3}
            multiline
            placeholder=""
            errorText={formErrors.desc ? formErrors.desc[0] : ""}
            required
          />
          <Box className={styles.profileHelperText}>{`${charCount}/${maxChars} characters`}</Box>
          {loading && <Skeleton variant="rectangular" height={100} />}
        </Box>
      </CardContent>
    </Card>
  );
};
