import React from 'react';
import SnakeGameLoadingComponent from 'components/Loading/index';

//--------------------------------------------------------------------------------------------------

const SnakeGame = () => {
  return (<SnakeGameLoadingComponent fromCreatePlan={false} />);
};

export default SnakeGame;
