import Cookies from 'js-cookie';

export const getAccessToken = () => {
  return Cookies.get("accessToken");
};

export const getRefreshToken = () => {
  return Cookies.get("refreshToken");
};

export const setCookies = (keyName, val) => {
  Cookies.set(keyName, val, { expires: 7 });
  return;
};

export const removeCookies = (keyName) => {
  Cookies.remove(keyName);
  return;
};

export const getHeaders = () => {
  const accessToken = Cookies.get("accessToken");
  if (!accessToken) {
    throw new Error("Access token is missing");
  }
  return { Authorization: `Bearer ${accessToken}` };
};

export const formatComments = (comments) => {
  return Array.isArray(comments) ? comments.map(comment => ({
    userId: comment.user.id.toString(),
    comId: comment.id.toString(),
    fullName: `${comment.user.first_name} ${comment.user.last_name}`,
    avatarUrl: comment.user.avatar,
    text: comment.content,
    created_at: comment.created_at,
    replies: []
  })) : [];
};
