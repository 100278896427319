/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import axios from 'axios';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  CardTitle
} from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";
import { APIURL } from "helpers/constants";
import styles from './styles.module.scss';

const Reset = () => {
  const { uid, token } = useParams();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [errors, setErrors] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleChangePasswordConfirm = (event) => {
    setPasswordConfirm(event.target.value);
  };

  const handleResetPassword = async () => {
    try {
      await axios.post(`${APIURL}/api/user/password-reset/${uid}/${token}/`,
        { password, password_confirm: passwordConfirm },
        { headers: { 'Content-Type': 'application/json' } }
      );
      setIsSuccess(true);
      setTimeout(() => {navigate('/login', { replace: true })}, 3000);
    } catch (error) {
      if (typeof (error?.response?.data?.message) == 'string') {
        setErrors({password_confirm: [error.response.data.message]});
      } else {
        setErrors(error.response?.data.message || {});
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleResetPassword();
    }
  };

  return (
    <>
      {!isSuccess ? (
        <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-4 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Enter your new password to reset</small>
              </div>
              <Form role="form" onKeyPress={handleKeyPress}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="New Password"
                      type="password"
                      autoComplete="new-password"
                      value={password}
                      onChange={handleChangePassword}
                    />
                  </InputGroup>
                  {errors.password && <div className="error-message">{errors.password[0]}</div>}
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Confirm New Password"
                      type="password"
                      autoComplete="new-password"
                      value={passwordConfirm}
                      onChange={handleChangePasswordConfirm}
                    />
                  </InputGroup>
                  {errors.password_confirm && <div className="error-message">{errors.password_confirm[0]}</div>}
                </FormGroup>
                <div className="text-center">
                  <Button className="mt-4" color="primary" type="button" onClick={handleResetPassword}>
                    Reset password
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      ) : (
        <Col lg="6" md="8">
          <Card className={`bg-secondary shadow border-0 ${styles.customMessageCard}`}>
            <CardBody className="px-lg-3 py-lg-7 text-center">
              <CardTitle className="mb-4" style={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'lightgreen' }}>Success message</CardTitle>
              <div style={{ fontSize: '1.2rem', color: 'white' }}>
                Your password has been reset successfully.
              </div>
            </CardBody>
          </Card>
        </Col>
      )}
    </>
  );
};

export default Reset;
