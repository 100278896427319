import { useState } from "react";
import { Card, CardBody, FormGroup, Form, Col, CardTitle, Row, Spinner } from "reactstrap";
import { InputAdornment, Button } from "@mui/material";
import axios from 'axios';
import Iconify from 'components/Iconify/index';
import { iconPrimaryColor, APIURL } from "helpers/constants";
import InputBase from 'components/Input/InputBase';
import styles from './styles.module.scss';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleResetPassword = async () => {
    if (!email) {
      setErrors({ email: ["This field is required!"] });
      return;
    }

    try {
      await axios.post(`${APIURL}/api/user/forgot-password`, { email },
        { headers: { 'Content-Type': 'application/json' } }
      );
      setIsSuccess(true);
    } catch (error) {
      console.error("error:: ", error);
      setErrors(error.response?.data || {});
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleResetPassword();
    }
  };

  return (
    <>
      {!isSuccess ? (
        <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-4 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Enter your email to receive a password reset link</small>
              </div>
              <Form role="form" onKeyPress={handleKeyPress}>
                <FormGroup className="mb-3">
                  <InputBase
                    labelText=""
                    type="email"
                    handleChange={handleChange}
                    errorText={errors.email}
                    placeholder="Email"
                    value={email}
                    keyword="email"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="ic:baseline-email" width={24} color={iconPrimaryColor} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errors.email && <div className="error-message">{errors.email[0]}</div>}
                </FormGroup>
                <div className="text-center">
                  <Button onClick={handleResetPassword} variant="contained" className={styles.submitBtn}>
                    {loading ? <Spinner size="sm" /> : 'Reset your password'}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-2 justify-content-center">
            <a href="/login" className={styles.customeALink}>
              Already have an account?
            </a>
          </Row>
        </Col>
      ) : (
        <Col lg="6" md="8">
          <Card className={`bg-secondary shadow border-0 ${styles.customMessageCard}`}>
            <CardBody className="px-lg-3 py-lg-7 text-center">
              <CardTitle className="mb-4" style={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'lightgreen' }}>Success message</CardTitle>
              <div style={{ fontSize: '1.2rem', color: 'white' }}>
                Reset password link has been sent to your email.
              </div>
            </CardBody>
          </Card>
        </Col>
      )}
    </>
  );
};

export default ForgotPassword;
