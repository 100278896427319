import React, { useState } from 'react';
import { Box, Dialog, DialogTitle, Button, Grid, IconButton } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import InputSelectBase from 'components/Input/InputSelectBase';
import { successSnackbar, errorSnackbar } from 'components/Snackbar/index';
import { mainYellowColor } from "helpers/constants";
import { assignUserToTask } from './services';
import Iconify from 'components/Iconify/index';
import styles from './styles.module.scss';

const AssignComponent = ({ open, onClose, users, selectedUserIds = [], onUsersSelect, task }) => {
  const [selectedUsers, setSelectedUsers] = useState(selectedUserIds);
  const handleAddUser = () => {
    setSelectedUsers([...selectedUsers, '']);
  };

  const handleRemoveUser = (index) => {
    const newSelectedUsers = selectedUsers.filter((_, i) => i !== index);
    setSelectedUsers(newSelectedUsers);
  };

  const handleUserChange = (index, userId) => {
    const newSelectedUsers = [...selectedUsers];
    newSelectedUsers[index] = userId;
    setSelectedUsers(newSelectedUsers);
  };

  const handleSave = async () => {
    try {
      for (const userId of selectedUsers.filter((userId) => userId)) {
        await assignUserToTask(task.slug, userId);
      }
      onUsersSelect(selectedUsers.filter((userId) => userId));
      onClose();
      successSnackbar('Users assigned successfully!');
    } catch (error) {
      console.error('Failed to assign users to task:', error);
      errorSnackbar('Failed to assign users.');
    }
  };

  const getFilteredOptions = () => {
    return users.filter((user) => !selectedUsers.includes(user.id))
      .map((user) => ({
        value: user.id,
        label: `${user?.invited_user_info?.first_name} ${user?.invited_user_info?.last_name}`,
        avatar: user.avatar_url,
      }));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ paddingBottom: 0, fontSize: '1.5rem', fontFamily: 'Recursive Variable' }}>Select Assign Members</DialogTitle>
      <Box sx={{ padding: '0 24px' }}>
        {selectedUsers.map((userId, index) => (
          <Grid container spacing={0} alignItems="center" key={index}>
            <Grid item xs={11}>
              <Box sx={{ marginTop: '10px' }}>Assign Member {index + 1}</Box>
              <InputSelectBase
                name={`user_select_${index}`}
                keyword={`user_select_${index}`}
                handleChange={(keyword, value) => handleUserChange(index, value)}
                value={userId}
                options={getFilteredOptions()}
                showAvatar={true}
              />
            </Grid>
            <Grid item xs={1} className='d-flex justify-content-end'>
              {selectedUsers.length > 1 && (
                <IconButton onClick={() => handleRemoveUser(index)} aria-label="delete" sx={{ color: 'black' }}>
                  <DeleteIcon sx={{ marginTop: "35px" }} />
                </IconButton>
              )}
            </Grid>
          </Grid>
        ))}
      </Box>
      <Box className='d-flex justify-content-between align-items-center' sx={{ padding: '18px 24px 24px' }}>
        <Box>
          <Button onClick={handleAddUser} variant="outlined" className={styles.addBtnBase}>
            <span><Iconify icon="gala:add" width={24} height={24} color={mainYellowColor} /></span>
            Add
          </Button>
        </Box>
        <Box>
        <Button onClick={handleSave} variant="outlined" className={styles.addBtnBase}>
            <span><Iconify icon="material-symbols:save" width={24} height={24} color={mainYellowColor} /></span>
            Save
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AssignComponent;
