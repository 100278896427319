/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import axios from 'axios';
import { NavbarBrand, Navbar } from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { setUser, clearUser } from '../../redux/userSlice';
import Iconify from 'components/Iconify/index';
import { APIURL } from "helpers/constants";
import { getHeaders, getRefreshToken, getAccessToken, setCookies, removeCookies } from "helpers/functions";
import styles from './styles.module.scss'

const SidebarComponent = (props) => {
  const { logo, onCollapseChange } = props;
  const [collapsed, setCollapsed] = React.useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    fetchAccountInfo();
  }, []);

  const fetchAccountInfo = async () => {
    if (!getAccessToken() && !getRefreshToken()) {
      navigate("/login", { replace: true });
      return;
    }

    try {
      const response = await axios.get(`${APIURL}/api/user/view-profile`, { headers: getHeaders() });

      if (response.status === 200) {
        dispatch(setUser({
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          avatar: response.data.avatar,
          id: response.data.id,
        }));
      } else {
        handleUnauthorized(response.status, getRefreshToken());
      }
    } catch (error) {
      handleFetchError(error, getRefreshToken());
    }
  };

  const handleUnauthorized = async (status, refreshToken) => {
    if (status === 401 && refreshToken) {
      try {
        await refreshTokenFunc(refreshToken);
        await fetchAccountInfo();
      } catch {
        redirectToLogin();
      }
    } else {
      redirectToLogin();
    }
  };

  const handleFetchError = async (error, refreshToken) => {
    if (refreshToken) {
      try {
        await refreshTokenFunc(refreshToken);
        await fetchAccountInfo();
      } catch {
        redirectToLogin();
      }
    } else {
      redirectToLogin();
    }
    console.error(error);
  };

  const refreshTokenFunc = async (refreshToken) => {
    try {
      const refreshResponse = await axios.post(`${APIURL}/api/user/token-refresh`, { refresh: refreshToken });
      const newAccessToken = refreshResponse.data.access;
      setCookies('accessToken', newAccessToken)
      return newAccessToken;
    } catch (error) {
      console.error('Error refreshing token:', error);
      removeCookies('accessToken');
      removeCookies('refreshToken');
      throw error;
    }
  };

  const redirectToLogin = () => {
    removeCookies('accessToken');
    removeCookies('refreshToken');
    navigate("/login", { replace: true });
  };

  const handleLogout = () => {
    removeCookies('accessToken');
    removeCookies('refreshToken');
    dispatch(clearUser());
    navigate('/login', { replace: true });
  };

  const handleMouseEnter = () => {
    setCollapsed(false);
    onCollapseChange(false);
  };

  const handleMouseLeave = () => {
    setCollapsed(true);
    onCollapseChange(true);
  };

  return (
    <Navbar className={`navbar-vertical fixed-left minimizeBar ${collapsed ? "collapsed" : ""} ${styles.customeNavbar}`}
      expand="md"
      id="sidenav-main"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      {logo && (
        <NavbarBrand className="pt-0">
          <img
            alt={logo.imgAlt}
            className="navbar-brand-img"
            src={logo.imgSrc}
            style={{ marginTop: '20px' }}
          />
        </NavbarBrand>
      )}
      <Sidebar collapsed={collapsed} className={styles.sidebarMain}>
        <Menu className={styles.sidebarMenu}>
          <MenuItemLink
            to="/d/"
            active={location.pathname === "/d/" || (location.pathname.includes('/d/plan/') && location.pathname !== "/d/plan/create")}
            iconName="mage:dashboard-check"
            text="My Plans"
          />
          <MenuItemLink
            to="/d/plan/create"
            active={location.pathname === "/d/plan/create"}
            iconName="icons8:idea"
            text="New Idea"
          />
          <MenuItemLink
            to="/d/my/tasks/calendar"
            active={location.pathname === "/d/my/tasks"}
            iconName="ion:calendar-sharp"
            text="Tasks Schedule"
          />
          <MenuItemLink
            to="/d/my/tasks/table"
            active={location.pathname === "/d/my/tasks"}
            iconName="icon-park-solid:checklist"
            text="To-Do List"
          />
          <MenuItemLink
            to="/d/follows"
            active={location.pathname === "/d/follows"}
            iconName="fluent:person-28-filled"
            text="Connect with Friends"
          />
          <MenuItemLink
            to="/d/notifications"
            active={location.pathname === "/d/notifications"}
            iconName="ion:notifications-sharp"
            text="Notifications"
          />
        </Menu>
      </Sidebar>
      <SidebarFooter user={user} collapsed={collapsed} handleLogout={handleLogout} />
    </Navbar >
  );
};

const MenuItemLink = ({ to, active, iconName, text }) => (
  <MenuItem
    active={active}
    component={<Link to={to} />}
    className={styles.menuItem}
    icon={<Iconify icon={iconName} width={28} height={28} />}>
    {text}
  </MenuItem>
);

const SidebarFooter = ({ user, collapsed, handleLogout }) => {
  const location = useLocation(); // Get the current route

  // Determine if the profile page is active
  const isProfileActive = location.pathname === '/d/profile/';

  return (
    <div className={styles.sidebarFooter} style={{ justifyContent: collapsed ? 'center' : 'space-between' }}>
      <div className="d-flex align-items-center">
        <span
          className={`avatar avatar-sm rounded-circle ${isProfileActive ? 'avatarActive' : ''}`} // Ensure no undefined class
        >
          <img alt="..." src={user.avatar} className="h-100" />
        </span>
        {!collapsed && (
          <div className="ml-2 d-lg-block">
            <span className="mb-0 text-sm font-weight-bold">
              <a href="/d/profile/" className={styles.avatarName}>
                {user.first_name} {user.last_name}
              </a>
            </span>
          </div>
        )}
      </div>
      {!collapsed && (
        <div className={styles.sidebarLogoutIcon} onClick={handleLogout}>
          <Iconify icon="quill:off" width={28} height={28} color="white" />
        </div>
      )}
    </div>
  );
};


export default SidebarComponent;
