import React from 'react';
import { Container } from '@mui/material';
import styles from './styles.module.scss';

const Services = () => (
  <section id="services" className={styles.servicesSection}>
    <Container className="main-container" style={{ padding: '30px 0 70px 0' }}>
      <h2 className={styles.titleSection}>Services</h2>
      <div className={styles.servicesGrid}>
        <div className={styles.service} data-aos="fade-up">
          <p className={styles.titleCard}>Project Planning</p>
          <p className={styles.subTitleCard}>Detailed planning and roadmap creation to ensure your project's success.</p>
        </div>
        <div className={styles.service} data-aos="fade-up" data-aos-delay="100">
          <p className={styles.titleCard}>Task Management</p>
          <p className={styles.subTitleCard}>Organize and prioritize tasks efficiently with our powerful tools.</p>
        </div>
        <div className={styles.service} data-aos="fade-up" data-aos-delay="200">
          <p className={styles.titleCard}>Team Collaboration</p>
          <p className={styles.subTitleCard}>Tools for effective team communication and collaboration.</p>
        </div>
        <div className={styles.service} data-aos="fade-up" data-aos-delay="300">
          <p className={styles.titleCard}>Progress Tracking</p>
          <p className={styles.subTitleCard}>Monitor project progress and milestones with our tracking features.</p>
        </div>
        <div className={styles.service} data-aos="fade-up" data-aos-delay="400">
          <p className={styles.titleCard}>Custom Reports</p>
          <p className={styles.subTitleCard}>Generate custom reports to gain insights into your project's performance.</p>
        </div>
      </div>
    </Container>
  </section>
);

export default Services;
