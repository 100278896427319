import React from 'react';
import { Box } from '@mui/material';
import InputBase from 'components/Input/InputBase';
import styles from '../styles.module.scss';

//--------------------------------------------------------------------------------------------------

const InputGroupComponent = ({ label, id, value, placeholder, handleChange, errorText, helperText, required, disabled }) => {
  return (
    <Box sx={{ marginBottom: 2 }}>
      <Box className={styles.profileLabelGroup}>{label}</Box>
      <InputBase
        id={id}
        value={value}
        placeholder={placeholder}
        handleChange={handleChange}
        errorText={errorText}
        helperText={helperText}
        required={required}
        disabled={disabled}
      />
    </Box>
  );
};

export default InputGroupComponent;
