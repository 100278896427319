import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from './redux/store';
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import LandingPage from "views/main/Landing.js";
import LoadingGame from "views/loading/index";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'semantic-ui-css/semantic.min.css';
import "assets/scss/main.scss";
import './index.css';

import '@fontsource-variable/recursive';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/d/*" element={<AdminLayout />} />
        <Route path="/*" element={<AuthLayout />} />
        <Route path="/" element={<LandingPage />} />
        <Route path="/g/loading" element={<LoadingGame />} />
      </Routes>
    </BrowserRouter>
  </Provider>
);
