/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Box,
  CircularProgress,
  Fab,
  Snackbar,
  IconButton,
  Container,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { useTasks } from 'hooks/useTasks';
import TaskTable from './table';
import AddTaskComponent from './addTaskComponent';
import ConfirmDialogComponent from 'components/Dialog/confirm';

// 1. TODO for NAV
// sửa lại giao diện trang này

const MyTaskForMe = () => {
  const {
    tasks,
    loading,
    loadingAdd,
    handleDeleteTask,
    handleAddTask,
    handleToggleComplete,
    handleUpdateTaskName,
    handleUpdateTaskDate
  } = useTasks();

  const [openAddTask, setOpenAddTask] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState(null);
  const [filter, setFilter] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleFilterChange = (event) => setFilter(event.target.value);
  const handleSearchChange = (event) => setSearchQuery(event.target.value);

  const filterTasks = (tasks) => {
    const today = new Date().toISOString().split('T')[0];
    return tasks.filter((task) => {
      if (searchQuery && !task.name.toLowerCase().includes(searchQuery.toLowerCase())) {
        return false;
      }
      switch (filter) {
        case 'today':
          return task.end_date === today;
        case 'future':
          return task.end_date > today;
        case 'past':
          return task.end_date < today;
        default:
          return true;
      }
    });
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container sx={{ paddingTop: '20px', minHeight: 'calc(100vh - 65px)' }} maxWidth='lg'>
      <Box className="d-flex justify-content-between align-items-center">
        <div>
          <FormControl component="fieldset">
            <RadioGroup row value={filter} onChange={handleFilterChange}>
              <FormControlLabel value="all" control={<Radio />} label="All" />
              <FormControlLabel value="today" control={<Radio />} label="Today" />
              <FormControlLabel value="future" control={<Radio />} label="Future" />
              <FormControlLabel value="past" control={<Radio />} label="Past" />
            </RadioGroup>
          </FormControl>
          <TextField
            variant="outlined"
            label="Search"
            size='small'
            value={searchQuery}
            onChange={handleSearchChange}
            sx={{ marginLeft: '20px' }}
          />
        </div>
        <Fab color="secondary" onClick={() => setOpenAddTask(true)}>
          <AddIcon />
        </Fab>
      </Box>
      <TaskTable
        tasks={filterTasks(tasks)}
        onToggleComplete={handleToggleComplete}
        onDelete={(taskId) => {
          setTaskToDelete(taskId);
          setConfirmOpen(true);
        }}
        onUpdateTaskName={handleUpdateTaskName}
        onUpdateTaskDate={handleUpdateTaskDate}
      />
      <AddTaskComponent
        open={openAddTask}
        onClose={() => setOpenAddTask(false)}
        onAddTask={handleAddTask}
        loading={loadingAdd}
      />
      <ConfirmDialogComponent
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        onConfirm={handleDeleteTask}
        title="Confirm Delete"
        description="Are you sure you want to delete this task?"
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={() => setSnackbarOpen(false)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Container>
  );
};

export default MyTaskForMe;
