import React from 'react';

const Footer = () => (
  <footer style={{
    backgroundColor: '#333',
    color: 'white',
    textAlign: 'center',
    padding: '20px'
  }}>
    <p style={{ fontFamily: 'Recursive Variable' }}>&copy; 2024 AI Planner. All rights reserved.</p>
  </footer>
);

export default Footer;
