import React, { useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { Box, IconButton, Typography, Grid, Card, CardContent, Chip, Avatar, Skeleton, Badge } from '@mui/material';
import { Link } from "react-router-dom";
import { mainYellowColor, btnPrimaryColor } from "helpers/constants";
import { getHeaders } from "helpers/functions";
import { APIURL } from "helpers/constants";
import Iconify from 'components/Iconify/index';
import FilterPopper from './popper';
import styles from "./styles.module.scss";

const Home = () => {
  const INIT_FILTER_DATA = { text: '', inviteUser: '', createdDate: '' };
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterAnchor, setFilterAnchor] = useState(null);
  const [filters, setFilters] = useState(INIT_FILTER_DATA);
  const [tempFilters, setTempFilters] = useState(INIT_FILTER_DATA);

  const fetchPlans = async (filters = {}) => {
    setLoading(true);
    try {
      let queryParams = '';
      if (filters.text) queryParams += `&name=${filters.text}`;
      if (filters.inviteUser) queryParams += `&invite_user=${filters.inviteUser}`;
      if (filters.createdDate) queryParams += `&created_date=${filters.createdDate}`;

      let url = `${APIURL}/api/retrieve/myplan?${queryParams}`;
      const response = await axios.get(url, { headers: getHeaders() });
      setPlans(response.data.data);
    } catch (error) {
      console.log('Error loading: ', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterClick = (event) => {
    setFilterAnchor(filterAnchor ? null : event.currentTarget);
  };

  const handleTempFilterChange = (e) => {
    setTempFilters({ ...tempFilters, [e.target.name]: e.target.value });
  };

  const handleApplyFilters = () => {
    setFilters(tempFilters);
    fetchPlans(tempFilters);
    setFilterAnchor(null);
  };

  const handleClosePopper = () => {
    setFilterAnchor(null);
  };

  const handleRemoveFilter = (filterName) => {
    const updatedFilters = { ...filters, [filterName]: '' };
    setFilters(updatedFilters);
    setTempFilters(updatedFilters);
    fetchPlans(updatedFilters);
  };

  const handleClearAllFilters = () => {
    setFilters(INIT_FILTER_DATA);
    setTempFilters(INIT_FILTER_DATA);
    fetchPlans(INIT_FILTER_DATA);
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  const open = Boolean(filterAnchor);
  const id = open ? 'filter-popper' : undefined;

  return (
    <Box className={styles.mainPage}>
      <Box className={styles.actionSection}>
        <Box className="d-flex align-items-center">
          <IconButton onClick={handleFilterClick} className={styles.homePageCommonIconButn}>
            <Iconify icon="mage:filter" width={24} height={24} color={mainYellowColor} />
          </IconButton>

          <Box className={styles.filterBoxParams}>
            {filters.text && (
              <Box className={styles.filterBoxParamUnit}>
                <Iconify icon="codicon:project" width={20} height={20} color={btnPrimaryColor} />
                <Chip
                  label={filters.text}
                  onDelete={() => handleRemoveFilter('text')}
                  className={styles.chipParamVal} />
              </Box>
            )}
            {filters.inviteUser && (
              <Box className={styles.filterBoxParamUnit}>
                <Iconify icon="ph:share" width={20} height={20} color={btnPrimaryColor} />
                <Chip
                  label={filters.inviteUser}
                  onDelete={() => handleRemoveFilter('inviteUser')}
                  className={styles.chipParamVal} />
              </Box>
            )}
            {filters.createdDate && (
              <Box className={styles.filterBoxParamUnit}>
                <Iconify icon="fluent:document-text-clock-24-regular" width={20} height={20} color={btnPrimaryColor} />
                <Chip
                  label={dayjs(filters.createdDate).format('MMM DD')}
                  onDelete={() => handleRemoveFilter('createdDate')}
                  className={styles.chipParamVal} />
              </Box>
            )}
            {(filters.text || filters.inviteUser || filters.createdDate) && (
              <IconButton onClick={handleClearAllFilters} sx={{ color: 'red', ml: 1 }}>
                <Iconify icon="fluent-mdl2:clear-filter" width={24} height={24} />
              </IconButton>
            )}
          </Box>
        </Box>
        <Link to="/d/plan/create" className={styles.homePageCommonIconButn}>
          <Iconify icon="icons8:idea" width={24} height={24} color={mainYellowColor} />
        </Link>
      </Box>

      <FilterPopper
        id={id}
        open={open}
        anchorEl={filterAnchor}
        filters={tempFilters}
        onFilterChange={handleTempFilterChange}
        onApplyFilters={handleApplyFilters}
        onClose={handleClosePopper}
        setFilters={setTempFilters}
      />

      <Grid container spacing={2} style={{ display: "flex", justifyContent: "center" }}>
        {loading ? (<>
          {Array.from(new Array(5)).map((_, index) => (
            <Grid item xs={12} key={index}>
              <Card>
                <CardContent>
                  <Box className={styles.cardSectionContent}>
                    <Skeleton variant="rectangular" width={30} height={30} />
                    <Typography variant="h6" className={styles.planName}>
                      <Skeleton variant="text" width={100} />
                    </Typography>
                  </Box>
                  <Box className={styles.cardSectionContent}>
                    <Skeleton variant="text" width={200} />
                  </Box>
                  <Skeleton variant="text" width="80%" />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </>) : plans.length > 0 ? (
          plans.map((plan, index) => (
            <Grid item xs={12} key={index}>
              <Link to={`/d/plan/${plan.slug}`} style={{ textDecoration: 'none' }}>
                <Card>
                  <CardContent>
                    <Box className={styles.cardSectionContent}>
                      <Iconify icon="codicon:project" width={30} height={30} color={mainYellowColor} />
                      <Typography variant="h6" className={styles.planName}>{plan.name}</Typography>
                    </Box>
                    <Box className={styles.cardSectionContent}>
                      {(plan.start_date || plan.end_date) && (
                        <Box sx={{ marginRight: '15px', display: 'flex', alignItems: 'center' }}>
                          <Box sx={{ marginRight: '10px' }}>
                            <Iconify icon="carbon:calendar" width={24} height={24} color={mainYellowColor} />
                          </Box>
                          {plan.start_date && (<Box>{dayjs(plan.start_date).format('MMM DD')}</Box>)}
                          {plan.start_date && plan.end_date && (<Box sx={{ mx: 0.1 }}>~</Box>)}
                          {plan.end_date && (<Box>{dayjs(plan.end_date).format('MMM DD')}</Box>)}
                        </Box>
                      )}
                      <Box sx={{ marginRight: '15px', display: 'flex', alignItems: 'center' }}>
                        <Iconify icon="fluent:document-text-clock-24-regular" width={24} height={24} color={mainYellowColor} />
                        <Typography variant="body2" className={styles.createdAtInfo}>
                          {dayjs(plan.created_at).format('H:m MMM DD')}
                        </Typography>
                      </Box>
                      {plan?.total_comments > 0 && (
                        <Box className={styles.boxTaskAction} sx={{ marginRight: "16px" }}>
                          <Badge badgeContent={plan.total_comments} color="error">
                            <Iconify icon="la:comment-solid" width={24} height={24} color={mainYellowColor} />
                          </Badge>
                        </Box>
                      )}
                      {plan?.total_memos > 0 && (
                        <Box className={styles.boxTaskAction}>
                          <Badge badgeContent={plan.total_memos} color="error">
                            <Iconify icon="fluent:note-48-regular" width={24} height={24} color={mainYellowColor} />
                          </Badge>
                        </Box>
                      )}
                    </Box>
                    {plan?.invitations?.length > 0 && (
                      <Box className={styles.cardSectionContent}>
                        <Iconify icon="ph:share" width={25} height={25} color={mainYellowColor} />
                        {plan.invitations.map((inviteUser, index) => (
                          <Chip
                            key={index}
                            avatar={<Avatar src={inviteUser.invited_user_info?.avatar || ''} />}
                            label={inviteUser?.invited_user_info ? inviteUser?.invited_user_info?.first_name : inviteUser.email}
                            variant="outlined"
                            className={styles.invitedUser}
                          />
                        ))}
                      </Box>
                    )}
                    <Box className={styles.cardSectionContent1}>
                      <Box sx={{ width: '24px' }}>
                        <Iconify icon="fluent:text-description-16-regular" width={24} height={24} color={mainYellowColor} />
                      </Box>
                      <Typography variant="body2" className={styles.planDesc}>
                        {plan.description}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))
        ) : (
          <Grid container className={styles.noDataPlanMain}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Iconify icon="mdi:folder-open" width={80} height={80} color={mainYellowColor} />
              <Typography variant="h6" className={styles.title}>
                Oops! No plans found.
              </Typography>
              <Typography variant="body2" className={styles.subTitle}>
                It seems like there are no plans available right now.
              </Typography>
              <Typography variant="body2" className={styles.subTitle}>
                Click the button above to create a new plan and get started!
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Home;
