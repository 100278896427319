import React from 'react';
import { Container } from '@mui/material';
import styles from './styles.module.scss';

const About = () => (
  <section id="about" className={styles.aboutSection}>
    <Container className="main-container" style={{ padding: '30px 0 70px 0' }}>
      <h2 className={styles.titleSection}>About Us</h2>
      <div className={styles.aboutContent}>
        <div className={styles.aboutText} data-aos="fade-right">
          <p>We are dedicated to helping you plan and execute your projects with the power of AI. Our mission is to simplify project planning and make it accessible to everyone.</p>
          <p>Our team is comprised of experienced professionals from various fields, all working together to bring you the best in project management technology.</p>
          <p>We believe in continuous improvement and are always looking for ways to enhance our platform and services. With our AI-powered solutions, you can ensure your projects are on track and successful.</p>
          <p>Our core values are innovation, collaboration, and excellence. We strive to provide top-notch services and create value for our clients through our cutting-edge technology.</p>
        </div>
        <div className={styles.aboutImage} data-aos="fade-left">
          <img src={require("../../assets/landing-about-image.png")} style={{ height: '300px', aspectRatio: 'auto'  }} alt="Team working" />
        </div>
      </div>
    </Container>
  </section>
);

export default About;
