import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, List, Box, Typography, Button, Chip, Avatar, Skeleton } from '@mui/material';
import usePlanDetail from 'hooks/usePlanDetail';
import MilestoneComponent from 'components/Plan/MilestoneComponent';
import ConfirmDialogComponent from 'components/Dialog/confirm';
import Iconify from 'components/Iconify/index';
import InviteDialogComponent from './dialog/invite';
import { mainYellowColor } from "helpers/constants";
import { successSnackbar, errorSnackbar } from 'components/Snackbar';
import { checkUserExistence, sendInvitation, getInvitedUsers, deletePlan } from './services';
import styles from './styles.module.scss';

//--------------------------------------------------------------------------------------------------

// 3. TODO for Minh
// thêm xử lý của với các plan không thể truy cập, các lỗi về plan không có
// hiện trạng: đang bị lỗi khi vào plan mình không có quyền truy cập, hoặc plan 404
// ví dụ: http://localhost:3000/d/plan/1cb2f1d0-12c2-4bfc-8d11-3da8e747db18
// mong muốn khi vào plan đó, bị lỗi 403 Forbidden thì hiển thị báo không có quyền truy cập
// B1: thao tác ở file hooks/usePlanDetail.js
// sửa lại usePlanDetail.js để kiểm tra khi xem có vào catch error không?
// chỉ cần set state error là true ở trong catch error là được
// nó sẽ bao trọn các trường hợp khi fetch data bị error
// B2: lấy giá trị error từ hook (dòng code 41)
// B3: kiểm tra giá trị của forbidden nếu true thì return luôn phần code giao diện (đặt trước return chính)
// if (error) {
// return (<><div>error</div></>)
//  }
// có thể tham khảo với dòng 226~239 của views/home/index.js
// để dùng layout có chung giao diện luôn, chỉ cần đổi icon và text nội dung ở trong

const PlanDetail = () => {
  const navigate = useNavigate();
  const { param } = useParams();
  const {
    planInfo,
    loading,
    setSelectedUsers,
    // error,  // Lấy giá trị forbidden từ hook
  } = usePlanDetail(param);

  const [email, setEmail] = useState('');
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [inviteError, setInviteError] = useState('');
  const curentUser = useSelector((state) => state.user);

  useEffect(() => {
    const fetchInvitedUsers = async () => {
      try {
        const invitedUsersList = await getInvitedUsers(param);
        setInvitedUsers(invitedUsersList);
      } catch (error) {
        console.error('Error fetching invited users:', error.message);
      }
    };

    fetchInvitedUsers();
  }, [param]);

  const handleEmailChange = (value) => {
    setEmail(value);
  };

  const handleSendEmail = async () => {
    try {
      const result = await checkUserExistence(email);
      if (result.exists) {
        await sendInvitation(planInfo, email);
        setInvitedUsers(prevUsers => [...prevUsers, { email }]);
        setEmail('');
        setEmailDialogOpen(false);
        successSnackbar(`${email} has been added to the plan.`);
      } else {
        setInviteDialogOpen(true);
      }
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        setInviteError(error.response.data.message)
      } else {
        errorSnackbar(`Failed to send invitation to ${email}`);
      }
    }
  };

  const handleInviteUser = async () => {
    try {
      await sendInvitation(planInfo, email);
      setInvitedUsers(prevUsers => [...prevUsers, { email }]);
      setInviteDialogOpen(false);
      setEmail('');
      setEmailDialogOpen(false);
      successSnackbar(`Invitation sent to ${email}`);
    } catch (error) {
      console.error('Error sending invitation:', error.message);
      errorSnackbar(`Failed to send invitation to ${email}`);
    }
  };

  const handleDeletePlan = async () => {
    try {
      await deletePlan(planInfo.slug);
      successSnackbar('Plan has been deleted successfully.');
      setDeleteDialogOpen(false);
      navigate('/d/api/plan/delete/<slug:slug>');
    } catch (error) {
      errorSnackbar('Failed to delete the plan.');
      setDeleteDialogOpen(false);
    }
  };

  return (
    <>
      {loading ? (
        <Container sx={{ padding: '30px 0 0' }}>
          <Box sx={{ paddingBottom: '24px' }}>
            <Box className={styles.headerPlan}>
              <Skeleton variant="rectangular" width="94%" height={100} sx={{ marginTop: 2 }} />
            </Box>
            <Box>
              <Skeleton variant="text" width="94%" height={40} sx={{ marginTop: 1 }} />
              <Skeleton variant="text" width="94%" height={40} sx={{ marginTop: 1 }} />
              <Skeleton variant="text" width="94%" height={40} sx={{ marginTop: 1 }} />
            </Box>
            <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Skeleton variant="text" width={500} height={30} />
              <Skeleton variant="circular" width={24} height={24} />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Skeleton variant="rectangular" width="94%" height={60} />
              <Skeleton variant="rectangular" width="94%" height={60} />
              <Skeleton variant="rectangular" width="94%" height={60} />
            </Box>
          </Box>
          <List style={{ padding: 0 }}>
            {[...Array(4)].map((_, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                <Skeleton variant="rectangular" width="100%" height={100} />
              </Box>
            ))}
          </List>
        </Container>
      ) : (
        <Container className={styles.planDetailContainer} sx={{ padding: '30px 0 0' }}>
          <Box sx={{ paddingBottom: '24px' }}>
            <Box className={styles.headerPlan}>
              <Iconify icon="ant-design:project-outlined" width={50} height={50} color={mainYellowColor} />
              <Typography variant="h4" component="h1" gutterBottom>
                {planInfo?.name}
              </Typography>
            </Box>
            <Box className={styles.bodyPlan}>
              <Iconify icon="fluent:text-description-32-filled" width={32} height={32} color={mainYellowColor} />
              <Typography variant="subtitle1" component="p">
                {planInfo?.description}
              </Typography>
            </Box>
            {planInfo.user.id === curentUser.id && (
              <Box className="d-flex">
                <Button onClick={() => console.log("editActionBtn")} className={styles.editActionBtn}>
                  <span><Iconify icon="raphael:edit" width={26} height={26} color={mainYellowColor} /></span>
                  Edit Plan
                </Button>
                <Button onClick={() => setDeleteDialogOpen(true)} className={styles.deleteActionBtn}>
                  <span><Iconify icon="eva:trash-2-outline" width={26} height={26} color="red" /></span>
                  Delete Plan
                </Button>
              </Box>
            )}
            <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="h6" component="h2" gutterBottom className={styles.invitedUser}>
                <Iconify icon="fluent:share-48-filled" width={30} height={30} color={mainYellowColor} />
                <span>Invited Users</span>
              </Typography>
              {/* 4. TODO for Minh */}
              {/* thêm điều kiện chỉ người tạo plan mới có thể invite */}
              {/* có thể tham khảo code ở dòng 159 file này */}
              <Button onClick={() => setEmailDialogOpen(true)} className={styles.inviteActionBtn}>
                <span><Iconify icon="mdi:email-outline" width={28} height={28} color={mainYellowColor} /></span>
                Invite via Email
              </Button>
            </Box>
            <Box sx={{ mt: 0, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {invitedUsers.length === 0 ? (
                <Typography variant="body1" className={styles.noInviteUser}>
                  No users have been invited to this plan yet.
                </Typography>
              ) : (
                <Box sx={{ mt: 0, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {invitedUsers.map((user, index) => (
                    <Chip
                      key={index}
                      avatar={<Avatar src={user.invited_user_info?.avatar || ''} />}
                      label={user?.invited_user_info ? user?.invited_user_info?.first_name : user.email}
                      variant="outlined"
                      sx={{ backgroundColor: '#333', color: 'white', fontWeight: 'bold' }}
                    />
                  ))}
                </Box>
              )}
            </Box>
          </Box>
          <List style={{ padding: 0 }}>
            {planInfo?.milestones.map((milestone, milestoneIndex) => (
              <MilestoneComponent
                key={milestone.id}
                milestone={milestone}
                setSelectedUsers={setSelectedUsers}
                users={invitedUsers}
              />
            ))}
          </List>
        </Container>
      )}

      <InviteDialogComponent
        open={emailDialogOpen}
        onClose={() => setEmailDialogOpen(false)}
        email={email}
        handleEmailChange={handleEmailChange}
        handleSendEmail={handleSendEmail}
        inviteError={inviteError}
      />
      <ConfirmDialogComponent
        open={inviteDialogOpen}
        onClose={() => setInviteDialogOpen(false)}
        onConfirm={handleInviteUser}
        title="User not found!"
        description={`The email "${email}" does not exist in the system. Would you like to send an invitation?`}
      />
      <ConfirmDialogComponent
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleDeletePlan}
        title="Delete Plan"
        description="Are you sure you want to delete this plan? This action cannot be undone."
      />
    </>
  );
};

export default PlanDetail;
