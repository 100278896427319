import EditableLabel from 'react-inline-editing';
import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardHeader, CardContent, List, Button, Box } from '@mui/material';
import { successSnackbar, errorSnackbar } from 'components/Snackbar/index';
import { addTaskToMilestone, updateMilestone } from './services';
import TaskComponent from './TaskComponent';
import AddTaskDialogComponent from './addTaskDialogComponent';
import Iconify from 'components/Iconify/index';
import { mainYellowColor } from "helpers/constants";
import styles from './styles.module.scss';

const MilestoneComponent = ({
  milestone,
  setSelectedUsers,
  users,
}) => {
  const [tasks, setTasks] = useState(milestone.tasks);
  const [addTaskDialogOpen, setAddTaskDialogOpen] = useState(false);
  const [milestoneName, setMilestoneName] = useState(milestone.name);

  useEffect(() => {
    milestone.tasks = tasks;
  }, [tasks, milestone]);

  const handleTaskDelete = useCallback((taskId) => {
    const updatedTasks = tasks.filter(task => task.id !== taskId);
    setTasks(updatedTasks);
  }, [tasks]);

  const handleAddTask = async (milestone, taskName) => {
    try {
      const newTask = await addTaskToMilestone(milestone.id, taskName);
      setTasks([...tasks, newTask]);
      successSnackbar('Task added successfully!');
    } catch (error) {
      console.error('Error adding task:', error);
      errorSnackbar('Failed to add task. Please try again.');
    }
  };

  const handleMilestoneNameChange = async (newName) => {
    try {
      const updatedMilestone = { ...milestone, name: newName };
      await updateMilestone(updatedMilestone);
      setMilestoneName(newName);
      console.log('Updated milestone:', updatedMilestone)
      successSnackbar('Milestone name updated successfully!');
    } catch (error) {
      console.error('Error updating milestone name:', error.message);
      errorSnackbar('Failed to update milestone name.');
    }
  };

  const handleFocusOut = (newName) => {
    if (newName !== milestoneName) {
      handleMilestoneNameChange(newName);
    }
  };

  return (
    <Card sx={{ mb: 3, boxShadow: "0px 0px 10px 5px lightgray" }}>
      <CardHeader
        title={<Box className={styles.milestoneHeader}>
          <Iconify icon="clarity:flag-solid" width={30} height={30} color={mainYellowColor} />
          <EditableLabel
            text={milestoneName}
            labelClassName={styles.milestoneNameLabel}
            inputClassName={styles.milestoneNameInput}
            labelFontWeight="bold"
            inputFontWeight="bold"
            onFocusOut={handleFocusOut}
            labelPlaceHolder="Enter milestone name"
            inputWidth="98%" />
          </Box>
        }
        sx={{ backgroundColor: '#333' }}
        action={
          <Button variant="contained" className={styles.addTaskBtn}
            onClick={() => setAddTaskDialogOpen(true)}>
            <Iconify icon="mdi:task-add" width={30} height={30} color={mainYellowColor} />
          </Button>
        }
      />
      <CardContent sx={{ padding: '0 15px 0 !important' }}>
        <List>
          {tasks.map((task, taskIndex) => (
            <TaskComponent
              key={task.slug}
              task={task}
              onDelete={() => handleTaskDelete(task.id)}
              setSelectedUsers={setSelectedUsers}
              users={users}
              tasks={tasks}
              setTasks={setTasks}
              mileStoneId={milestone.id}
              taskIndex={taskIndex}
            />
          ))}
        </List>
      </CardContent>
      <AddTaskDialogComponent
        open={addTaskDialogOpen}
        onClose={() => setAddTaskDialogOpen(false)}
        milestone={milestone}
        handleAddTask={handleAddTask}
      />
    </Card>
  );
};

export default MilestoneComponent;
