import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { snackbarColor, primaryFont } from 'helpers/constants';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const successSnackbar = (message, isAuth, action, actionText) => {
  renderSnackbar(message, 'success', action, actionText, isAuth);
};

export const errorSnackbar = (message, isAuth, action, actionText) => {
  renderSnackbar(message, 'error', action, actionText, isAuth);
};

const renderSnackbar = (message, severity, action, actionText, isAuth) => {
  const snackbarContainer = document.createElement('div');
  document.body.appendChild(snackbarContainer);

  const root = ReactDOM.createRoot(snackbarContainer);

  const SnackbarComponent = () => {
    const [open, setOpen] = useState(true);

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    };

    const style = {
      top: isAuth ? '95px' : '20px',
    };

    let snackbarStyle = {
      backgroundColor: snackbarColor.successBgColor,
      color: snackbarColor.successColor,
      border: `1px solid ${snackbarColor.successborderColor}`,
      btnBackgroundColor: snackbarColor.successColor,
    };

    if (severity === 'error') {
      snackbarStyle = {
        backgroundColor: snackbarColor.errorBgColor,
        color: snackbarColor.errorColor,
        border: `1px solid ${snackbarColor.errorborderColor}`,
        btnBackgroundColor: snackbarColor.errorColor,
      };
    }

    return (
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        disableWindowBlurListener={true}
        style={style}
        sx={{
          '& .MuiAlert-root': {
            backgroundColor: snackbarStyle.backgroundColor,
            color: snackbarStyle.color,
            border: snackbarStyle.border,
            fontFamily: primaryFont,
            fontSize: '1rem',
            borderRadius: '10px',
            padding: '1px 20px 1px 10px',
          },
        }}>
        <Alert
          severity={severity}
          action={
            <>
              {action && (
                <Button
                  color="inherit"
                  size="small"
                  onClick={action}
                  sx={{
                    fontWeight: 'bold',
                    fontFamily: primaryFont,
                    backgroundColor: snackbarStyle.btnBackgroundColor,
                    color: 'white',
                    borderRadius: '6px',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    '&:hover': {
                      backgroundColor: snackbarStyle.btnBackgroundColor,
                    },
                  }}>
                  {actionText}
                </Button>
              )}
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }>
          {message}
        </Alert>
      </Snackbar>
    );
  };

  root.render(<SnackbarComponent />);
};
