import React from 'react';
import { TextField, Typography } from "@mui/material";

const InputPasswordBase = (props) => {
  const {
    labelText,
    handleChange,
    showPass,
    errorText,
    placeholder,
    id,
    helperText,
    value,
    name,
    disabled = false,
    className,
    size,
    InputProps
  } = props;

  const borderColor = '#9E9991';
  const primaryFont = 'Recursive Variable';

  return (
    <>
      <TextField
        label={labelText}
        disabled={disabled}
        id={id}
        type={showPass ? 'text' : 'password'}
        error={!!errorText}
        helperText={helperText}
        placeholder={placeholder}
        fullWidth
        value={value ?? ""}
        name={name}
        className={className}
        size={size}
        onChange={(e) =>
          name
            ? handleChange(name, e.target.value)
            : handleChange(e.target.value)
        }
        InputProps={InputProps}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderWidth: '1px',
              borderColor: borderColor,
              borderRadius: '5px',
              fontFamily: primaryFont,
            },
            '&:hover fieldset': {
              borderWidth: '1px',
              borderColor: borderColor,
              fontFamily: primaryFont,
            },
            '&.Mui-focused fieldset': {
              borderWidth: '1px',
              borderColor: borderColor,
              fontFamily: primaryFont,
            },
            '& .MuiInputBase-input': {
              display: 'flex',
              alignItems: 'center',
              height: '42px',
              padding: 0,
              fontFamily: primaryFont,
              fontSize: '1.125rem'
            }
          },
          backgroundColor: '#FFFFFF'
        }}
      />
      {errorText && (
        <Typography variant="body2" color="error" sx={{ marginTop: '5px', fontSize: '0.875rem' }}>
          {errorText}
        </Typography>
      )}
    </>
  )
}

export default InputPasswordBase;
