import React from 'react';
import { Container } from '@mui/material';
import styles from './styles.module.scss';


const Contact = () => (
  <section id="contact" className={styles.contactSection}>
    <Container className="main-container" style={{ padding: '30px 0 70px 0' }}>
      <h2 className={styles.titleSection} >Contact Us</h2>
      <form data-aos="fade-up" class={styles.contactUsForm}>
        <input type="text" name="name" placeholder="Your Name" required />
        <input type="email" name="email" placeholder="Your Email" required />
        <textarea name="message" placeholder="Your Message" required></textarea>
        <button type="submit">Send</button>
      </form>
      {/* <div className="contact-info">
        <p>Address: Hanoi, VietNam</p>
        <p>Phone: (0987) 654-321</p>
        <p>Email: contact@aiplanner.com</p>
      </div> */}
    </Container>
  </section>
);

export default Contact;
