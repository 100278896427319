/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PropTypes } from "prop-types";
import {
  NavbarBrand,
  Navbar,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import axios from 'axios';
import Cookies from 'js-cookie';

const MobileHeader = (props) => {
  const [, setNameMe] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const { logo } = props;
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL || "http://127.0.0.1:8000";
  const mainBackgroundColor = '#2b2b2b';

  useEffect(() => {
    fetchAccountInfo();
  }, []);

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  const refreshTokenFunc = async (refreshToken) => {
    try {
      const refreshResponse = await axios.post(
        `${apiUrl}/api/user/token-refresh`,
        { refresh: refreshToken }
      );

      const newAccessToken = refreshResponse.data.access;
      Cookies.set("accessToken", newAccessToken, { expires: 7 });
      return newAccessToken;
    } catch (error) {
      console.log("Error refreshing token:", error);
      Cookies.remove("accessToken");
      Cookies.remove("refreshToken");
      throw error;
    }
  };

  const fetchAccountInfo = async () => {
    const accessToken = Cookies.get("accessToken");
    const refreshToken = Cookies.get("refreshToken");

    if (!accessToken && !refreshToken) {
      navigate("/login", { replace: true });
      return;
    }

    try {
      const headers = { Authorization: "Bearer " + accessToken };
      const response = await axios.get(`${apiUrl}/api/user/view-profile`, { headers });

      if (response.status === 200) {
        setNameMe(response.data.first_name + " " + response.data.last_name);
        setAvatar(response.data?.avatar);

      } else {
        console.log("Non-200 response status:", response.status);

        if (response.status === 401 && refreshToken) {
          await refreshTokenFunc(refreshToken);
          await fetchAccountInfo();
        } else {
          navigate("/login", { replace: true });
        }
      }
    } catch (error) {
      if (refreshToken) {
        try {
          await refreshTokenFunc(refreshToken);
          await fetchAccountInfo();
        } catch (refreshError) {
          console.log("Error refreshing token:", refreshError);
          Cookies.remove("accessToken");
          Cookies.remove("refreshToken");
          navigate("/login", { replace: true });
        }
      } else {
        Cookies.remove("accessToken");
        Cookies.remove("refreshToken");
        navigate("/login", { replace: true });
      }

      console.log(error);
    }
  };

  return (
    <Navbar
      className={`navbar-vertical fixed-left minimizeBar`}
      expand="md"
      id="sidenav-main"
      style={{
        padding: 0,
        width: 'auto',
        backgroundColor: `${mainBackgroundColor}`,
        xOverflow: 'hidden',
        display: 'flex',
        justifyContent: 'space-between'
      }}>
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav>
          <img src={require("../../assets/list.svg").default} />
        </DropdownToggle>
        <DropdownMenu right style={{ backgroundColor: 'white', boxShadow: '5px 5px 5px lightgray', border: '1px lightgray solid' }}>
          <Link to="/d/">
            <DropdownItem>My Plan</DropdownItem>
          </Link>
          <Link to="/d/plan/create">
            <DropdownItem>Create Idea</DropdownItem>
          </Link>
          <Link to="/d/my/tasks">
            <DropdownItem>Tasks Schedule</DropdownItem>
          </Link>
          <Link to="/d/follows">
            <DropdownItem>Connect with Friends</DropdownItem>
          </Link>
          <Link to="/d/icons">
            <DropdownItem>Icons Set</DropdownItem>
          </Link>
        </DropdownMenu>
      </UncontrolledDropdown>
      {logo ? (
        <NavbarBrand className="pt-0" {...navbarBrandProps}>
          <img
            alt={logo.imgAlt}
            className="navbar-brand-img"
            src={logo.imgSrc}
            style={{ margin: '9px 0 9px 0' }}
          />
        </NavbarBrand>
      ) : null}
      <Link to="/d/profile">
        <Media className="align-items-center" style={{ margin: '0 10px 0 20px' }}>
          <span className="avatar avatar-sm rounded-circle">
            <img
              alt="..."
              src={avatar}
              className="h-100"
            />
          </span>
        </Media>
      </Link>
    </Navbar >
  );
};

MobileHeader.defaultProps = {
  routes: [{}],
};

MobileHeader.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default MobileHeader;
