import React, { useState } from 'react';
import { Box, TextField, Button, Modal, Typography } from '@mui/material';
import { TODO_STATUS } from "helpers/constants";

const AddTaskComponent = ({ open, onClose, onAddTask, loading }) => {
  const [newTaskName, setNewTaskName] = useState('');
  const [newTaskStartDate, setNewTaskStartDate] = useState('');
  const [newTaskEndDate, setNewTaskEndDate] = useState('');

  const handleAddTask = () => {
    onAddTask({
      name: newTaskName,
      start_date: newTaskStartDate,
      end_date: newTaskEndDate,
      status: TODO_STATUS,
    });
    setNewTaskName('');
    setNewTaskStartDate('');
    setNewTaskEndDate('');
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        component="form"
        sx={{
          position: 'absolute',
          top: '40%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          p: 4,
        }}>
        <Typography variant="h6">Add New Task</Typography>
        <TextField
          fullWidth
          label="Task Name"
          value={newTaskName}
          onChange={(e) => setNewTaskName(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Start Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={newTaskStartDate}
          onChange={(e) => setNewTaskStartDate(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="End Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={newTaskEndDate}
          onChange={(e) => setNewTaskEndDate(e.target.value)}
          margin="normal"
        />
        <Button variant="contained" color="primary" onClick={handleAddTask} fullWidth>
          {loading ? 'Loading...' : 'Add Task'}
        </Button>
      </Box>
    </Modal>
  );
};

export default AddTaskComponent;
