/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Box, Container, Typography, Avatar, Grid, Paper, Button, Pagination, Skeleton, Tabs, Tab, TextField, InputAdornment } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/AccessTime';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/system';
import { Link } from "react-router-dom";
import { fetchFollowDataService, sendFollowRequest, acceptFollowRequest, rejectFollowRequest } from './services';
import styles from './styles.module.scss';

const ContactCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  borderRadius: '10px',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
}));

const SkillChip = styled('div')(({ primary }) => ({
  borderRadius: '20px',
  border: primary ? '1px solid transparent' : '1px solid gray',
  padding: '1px 10px',
  marginRight: '5px',
  fontWeight: '500',
  backgroundColor: primary ? '#00509d' : 'transparent',
  color: primary ? 'white' : 'black',
}));

const FollowButton = styled(Button)(({ primary }) => ({
  borderRadius: "0px",
  backgroundColor: "white",
  color: "black",
  borderTop: "white",
  paddingTop: "10px",
  '&:hover': {
    backgroundColor: "#ffb703",
    color: "white",
  }
}));

const FollowButtonStyled = styled(Paper)(({ color }) => ({
  borderRadius: "0px !important",
  backgroundColor: color,
  color: "white",
  textAlign: 'center',
  padding: "10px 0",
  boxShadow: 'none',
  width: '100%',
  fontFamily: "Roboto",
  fontWeight: 500,
  fontSize: '0.875rem',
}));

const ContactDirectory = () => {
  const [data, setData] = useState({
    potential_follows: [],
    pending_received_requests: [],
    pending_sent_requests: [],
    friends: [],
  });
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [tabValue, setTabValue] = useState('potential_follows');
  const [searchQuery, setSearchQuery] = useState('');


  const fetchFollowData = useCallback(async (keyword) => {
    try {
      setLoading(true);
      const result = await fetchFollowDataService(keyword);
      setData(result);
      setTotalPages(Math.ceil(result.potential_follows.length / 15));
    } catch (error) {
      console.log('Error loading: ', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchFollowData();
  }, []);

  const handleFollow = async (id) => {
    try {
      await sendFollowRequest(id);
      fetchFollowData(searchQuery);
    } catch (error) {
      console.log('Error following user: ', error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = () => {
    fetchFollowData(searchQuery);
  };

  const handleAccept = async (id) => {
    try {
      await acceptFollowRequest(id);
      fetchFollowData(searchQuery);
    } catch (error) {
      console.log('Error accepting follow request: ', error);
    }
  };

  const handleReject = async (id) => {
    try {
      await rejectFollowRequest(id);
      fetchFollowData(searchQuery);
    } catch (error) {
      console.log('Error rejecting follow request: ', error);
    }
  };

  const renderSkeletons = () => {
    return [...Array(15)].map((_, index) => (
      <Grid item xs={12} sm={6} md={4} key={index}>
        <ContactCard
          sx={{
            borderTopLeftRadius: "10px !important",
            borderTopRightRadius: "10px !important",
            borderBottomLeftRadius: "0px !important",
            borderBottomRightRadius: "0px !important",
            borderBottom: '0 !important',
            paddingBottom: "10px !important",
          }}>
          <Skeleton variant="rectangular" width={130} height={130} sx={{ margin: '0 auto', marginBottom: "15px", marginTop: "0" }} />
          <Skeleton variant="text" width={100} height={20} sx={{ marginBottom: 1 }} />
          <Skeleton variant="text" width={80} height={16} sx={{ marginBottom: 1 }} />
          <Skeleton variant="text" width={150} height={16} sx={{ marginBottom: 1 }} />
          <Skeleton variant="text" width={200} height={16} sx={{ marginBottom: 1 }} />
          <Skeleton variant="text" width={150} height={16} sx={{ marginBottom: 1 }} />
        </ContactCard>
      </Grid>
    ));
  };

  const renderUserCard = (user, actionButtons) => (
    <Grid item xs={12} sm={6} md={4} key={user.id}>
      <ContactCard
        sx={{
          borderTopLeftRadius: "10px !important",
          borderTopRightRadius: "10px !important",
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderBottom: 0,
          padding: 0,
        }}>
        <Box sx={{ padding: "20px" }}>
          <Avatar alt={user.first_name} src={user.avatar} className={styles.avatarStyle}
          />
          <Typography variant="h6" component="div" className={styles.contactName}>
            {user.first_name} {user.last_name}
          </Typography>
          <div className={styles.contactWorkStatus}>
            <PersonIcon color="success" className={styles.contactWorkStatusIcon} />
            <Typography variant="body2" className={styles.contactWorkStatusText}>
              {user.occupation}
            </Typography>
          </div>
          <Box className={styles.contactSkills}>
            {user?.skills.slice(0, 3).map((skill) => (
              <SkillChip key={skill.id}>{skill.name}</SkillChip>
            ))}
            {user?.skills.length > 3 && (
              <SkillChip primary>
                + {user.skills.length - 3}
              </SkillChip>
            )}
            {user?.skills.length === 0 && (
              <SkillChip>
                No skills available
              </SkillChip>
            )}
          </Box>
          <Typography variant="body1" className={styles.contactDescription}>
            {user.description}
          </Typography>
        </Box>
        <Box className={styles.contactActions}>
          {actionButtons}
        </Box>
        <Box className={styles.contactActions}>
          <Button
            component={Link}
            to={`/d/other/profile/${user.email}`}
            variant="contained"
            fullWidth
            sx={{
              borderRadius: '0 0 10px 10px',
              backgroundColor: "white",
              color: "black",
              borderTop: "white",
              paddingTop: "12px",
              paddingBottom: "10px",
              '&:hover': {
                backgroundColor: "#00509d",
                color: "white",
              }
            }}>
            View Profile
          </Button>
        </Box>
      </ContactCard>
    </Grid>
  );

  const renderNoData = () => (
    <Typography variant="h6" align="center" sx={{ width: '100%', marginTop: 2 }}>
      No Data Available
    </Typography>
  );

  return (
    <Container>
      <Box sx={{ flexGrow: 1, paddingTop: "20px" }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 4 }}>
          <TextField
            label="Search"
            variant="outlined"
            fullWidth
            value={searchQuery}
            onChange={handleSearchChange}
            size="small"
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                handleSearch();
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button onClick={handleSearch}>
                    <SearchIcon />
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Tabs
          value={tabValue}
          onChange={(event, newValue) => setTabValue(newValue)}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab icon={<AllInclusiveIcon />} label="Potential Follows" value="potential_follows" />
          <Tab icon={<HourglassEmptyIcon />} label="Pending Sent Requests" value="pending_sent_requests" />
          <Tab icon={<PendingIcon />} label="Pending Received Requests" value="pending_received_requests" />
          <Tab icon={<CheckCircleIcon />} label="Friends" value="friends" />
        </Tabs>

        <Grid container spacing={4}>
          {loading ? renderSkeletons() : (
            tabValue === 'potential_follows' ? (
              data.potential_follows.length > 0 ? data.potential_follows.map(user => renderUserCard(user,
                <FollowButton variant="contained" fullWidth onClick={() => handleFollow(user.id)}>Follow</FollowButton>
              )) : renderNoData()
            ) : tabValue === 'pending_sent_requests' ? (
              data.pending_sent_requests.length > 0 ? data.pending_sent_requests.map(user => renderUserCard(user,
                <FollowButtonStyled color="#ff9800" disabled>WAITING</FollowButtonStyled>
              )) : renderNoData()
            ) : tabValue === 'pending_received_requests' ? (
              data.pending_received_requests.length > 0 ? data.pending_received_requests.map(user => renderUserCard(user,
                <>
                  <Button variant="contained" fullWidth sx={{ marginBottom: 1 }} onClick={() => handleAccept(user.id)}>Accept</Button>
                  <Button variant="contained" fullWidth onClick={() => handleReject(user.id)}>Reject</Button>
                </>
              )) : renderNoData()
            ) : tabValue === 'friends' ? (
              data.friends.length > 0 ? data.friends.map(user => renderUserCard(user,
                <FollowButtonStyled color="#4caf50" disabled>Friend</FollowButtonStyled>
              )) : renderNoData()
            ) : null
          )}
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={(event, value) => setPage(value)}
            color="primary"
          />
        </Box>
      </Box>
    </Container>
  );
};

export default ContactDirectory;