export const COMMON_PAGE_KEY = "common";
export const ADMIN_PAGE_KEY = "dashboard";
export const SIDEBAR_COLLAPSED_LEFT_PX = "80px";
export const SIDEBAR_EXPANED_LEFT_PX = "250px";
export const APIURL = process.env.REACT_APP_API_URL || "http://127.0.0.1:8000";
export const DONE_STATUS = 3;
export const TODO_STATUS = 1;
export const primaryColor = "#0F52BA";
export const btnPrimaryColor = "#333333";
export const iconPrimaryColor = "#4B4B4B";
export const mainYellowColor = "#F0A500";
export const mainRedColor = "#D3302F";
export const primaryFont = 'Recursive Variable';
export const snackbarColor = {
  successColor: '#0F52BA',
  successBgColor: '#DDEBFF',
  successborderColor: '#DDEBFF',
  errorColor: '#C41C1C',
  errorBgColor: 'white',
  errorborderColor: '#F09898',
  warningColor: '#EA9A3E',
  warningBgColor: '#F09898',
};
export const MAIN_LANGUES_OPTION = [
  { value: 'English', label: 'English' },
  { value: 'Tiếng Việt', label: 'Tiếng Việt' },
  { value: '日本語', label: '日本語' }
]