import React, { useState } from 'react';
import { Dialog, Typography, Button, Box } from '@mui/material';
import InputBase from 'components/Input/InputBase';
import { mainYellowColor } from "helpers/constants";
import Iconify from 'components/Iconify/index';
import styles from './styles.module.scss';

const AddSubtaskDialogComponent = ({
  open,
  onClose,
  task,
  handleAddSubtask,
}) => {
  const [subtaskName, setSubtaskName] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = () => {
    if (subtaskName.trim()) {
      handleAddSubtask(task, subtaskName);
      setSubtaskName('');
      setError('');
      onClose();
    } else {
      setError('Please enter a subtask name.');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Box className={styles.noteDialogContainer}>
        <Typography variant="h5" className={styles.noteTitle}>Add Subtask</Typography>
        <Box className={styles.subtaskInputContainer}>
          <InputBase
            id="subtask-input"
            value={subtaskName}
            placeholder="Enter subtask name..."
            handleChange={(value) => setSubtaskName(value)}
            errorText={error}
            helperText={error}
            required
            sx={{ flexGrow: 1, marginRight: 2 }}
          />
        </Box>
        <Box>
          <Button onClick={handleSubmit} variant="contained" color="primary" className={styles.addBtnBase}>
            <span><Iconify icon="gala:add" width={24} height={24} color={mainYellowColor} /></span>
            Add
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AddSubtaskDialogComponent;
