import React, { useEffect, useState } from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
import MobileHeader from "components/Sidebar/MobileHeader";
import AdminFooter from "components/Footers/AdminFooter.js";
import SidebarComponent from "components/Sidebar/SidebarComponent";
import routes from "routes/index";
import PrivateRoute from "PrivateRoute";
import { ADMIN_PAGE_KEY, SIDEBAR_COLLAPSED_LEFT_PX, SIDEBAR_EXPANED_LEFT_PX } from "helpers/constants";

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const [sidebarCollapsed, setSidebarCollapsed] = useState(true);
  const [isSmartphone, setIsSmartphone] = useState(window.innerWidth <= 768);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmartphone(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === ADMIN_PAGE_KEY) {
        const RouteComponent = prop.private ? PrivateRoute : React.Fragment;
        return (
          <Route path={prop.path} key={key}
            element={<RouteComponent>{prop.component}</RouteComponent>} />
        );
      }
      return null;
    });
  };

  const handleCollapseChange = (collapsed) => {
    setSidebarCollapsed(collapsed);
  };

  const mainContentStyle = {
    marginLeft: isSmartphone ? '0' : sidebarCollapsed ? SIDEBAR_COLLAPSED_LEFT_PX : SIDEBAR_EXPANED_LEFT_PX,
    transition: 'margin-left 0.3s'
  };

  return (
    <>
      {isSmartphone ? (
        <MobileHeader
          onCollapseChange={handleCollapseChange}
          logo={{
            innerLink: "/d/",
            imgSrc: require("../assets/main_logo.png"),
            imgAlt: "...",
          }}
          className="nav-collapse"
        />
      ) : (
        <SidebarComponent
          onCollapseChange={handleCollapseChange}
          logo={{
            innerLink: "/d",
            imgSrc: require(sidebarCollapsed ? "../assets/fire_logo.png" : "../assets/main_logo.png"),
            imgAlt: "...",
          }}
          className="nav-collapse"
        />
      )}
      <div
        className="main-content main-transition"
        ref={mainContent}
        style={mainContentStyle}>
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/d/" replace />} />
        </Routes>
        <AdminFooter />
      </div>
    </>
  );
};
export default Admin;