import axios from 'axios';
import { getHeaders } from "helpers/functions";
import { APIURL } from "helpers/constants";

export const fetchPlanInfo = async (param) => {
  try {
    const headers = getHeaders();
    const response = await axios.get(`${APIURL}/api/plans/${param}`, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const checkUserExistence = async (email) => {
  try {
    const formData = new FormData();
    formData.append('email', email);
    const response = await axios.post(`${APIURL}/api/check-user`, formData, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendInvitation = async (plan, email) => {
  try {
    const formData = new FormData();
    formData.append('email', email);
    const response = await axios.post(`${APIURL}/api/plans/${plan.slug}/invite`, formData, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getInvitedUsers = async (planSlug) => {
  try {
    const response = await axios.get(`${APIURL}/api/plans/${planSlug}/invited-users`, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    throw new Error('Error fetching invited users: ' + error.message);
  }
};


export const deletePlan = async (planSlug) => {
  try {
    const response = await axios.delete(`${APIURL}/api/plan/delete/${planSlug}`, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    throw new Error('Error deleting plan of user: ' + error.message);
  }
}