import { useState, useEffect } from 'react';
import { successSnackbar, errorSnackbar } from 'components/Snackbar/index';
import { fetchTasks, deleteTask, addTask, toggleTaskComplete, updateTask } from 'views/todo/services';



export const useTasks = () => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingAdd, setLoadingAdd] = useState(false);

  useEffect(() => {
    const loadTasks = async () => {
      setLoading(true);
      try {
        const data = await fetchTasks();
        setTasks(data || []);
      } catch (error) {
        errorSnackbar('Failed to fetch tasks');
      } finally {
        setLoading(false);
      }
    };

    loadTasks();
  }, []);

  const handleDeleteTask = async (taskId) => {
    try {
      const success = await deleteTask(taskId);
      if (success) {
        setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
        successSnackbar('Task deleted successfully');
      } else {
        errorSnackbar('Failed to delete task');
      }
    } catch (error) {
      errorSnackbar('Error deleting task');
    }
  };

  const handleAddTask = async (newTask) => {
    setLoadingAdd(true);
    try {
      await addTask(newTask);
      const data = await fetchTasks();
      setTasks(data.tasks || []);
      successSnackbar('Task added successfully');
    } catch (error) {
      errorSnackbar('Error adding task');
    } finally {
      setLoadingAdd(false);
    }
  };

  const handleToggleComplete = async (taskId, status) => {
    try {
      await toggleTaskComplete(taskId, status);
      setTasks((prevTasks) =>
        prevTasks.map((prevTask) => (prevTask.id === taskId ? { ...prevTask, status } : prevTask))
      );
      successSnackbar('Task updated successfully');
    } catch (error) {
      errorSnackbar('Error updating task');
    }
  };

  const handleUpdateTaskName = async (taskId, newName) => {
    try {
      const updatedTask = tasks.find((task) => task.id === taskId);
      if (!updatedTask) return;
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === taskId ? { ...task, name: newName } : task
        )
      );
      await updateTask({ ...updatedTask, name: newName });
      successSnackbar('Task name updated successfully');
    } catch (error) {
      successSnackbar('Error updating task name');
    }
  };

  const handleUpdateTaskDate = async (updatedTask) => {
    console.log('Task updated successfully::: ', updatedTask);
    try {
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === updatedTask.id ? updatedTask : task
        )
      );

      await updateTask(updatedTask);
      successSnackbar('Task date updated successfully');
    } catch (error) {
      errorSnackbar('Error updating task date');
    }
  };

  return {
    tasks,
    loading,
    loadingAdd,
    handleDeleteTask,
    handleAddTask,
    handleToggleComplete,
    handleUpdateTaskName,
    handleUpdateTaskDate,
  };
};
