/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { CircularProgress, Box, Typography, Card, CardContent } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";
import Cookies from 'js-cookie';
import { successSnackbar, errorSnackbar } from 'components/Snackbar/index';
import { APIURL } from "helpers/constants";
import styles from './styles.module.scss';

const GoogleAuthHandle = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const values = queryString.parse(location.search);
    const code = values.code ? values.code : null;
    if (code) {
      onGooglelogin();
    }
  }, []);

  const onGooglelogin = async () => {
    const status = await googleLoginHandler(location.search);
    if (status === 200) {
      successSnackbar("Login Success, you will be redirected in 3 seconds");
      setTimeout(() => { navigate('/d/') }, 3000);
    } else {
      errorSnackbar("Login Error. Something went wrong!", true);
      setTimeout(() => { navigate('/login') }, 3000);
    }
    return;
  }

  const googleLoginHandler = (code) => {
    return axios.get(`${APIURL}/api/auth/google${code}`).then((res) => {
      const { user, access_token, refresh_token } = res.data;
      Cookies.set('accessToken', access_token, { expires: 7 });
      Cookies.set('refreshToken', refresh_token, { expires: 7 });
      localStorage.setItem("email", user.email);
      return res.status;
    }).catch((err) => {
      return err;
    });
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="90vh" marginTop={-15}>
      <Card sx={{ width: '400px' }}>
        <CardContent>
          <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
            <CircularProgress />
            <Typography variant="body1" align="center" className={styles.processingTextAlert}>
              Processing your login, please wait...
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default GoogleAuthHandle;