import React, { useState, useEffect } from "react";
import { Grid, Card, Typography, Skeleton, Box, Badge } from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import { successSnackbar, errorSnackbar } from 'components/Snackbar/index';
import { ProfileComponent } from './_profile';
import { ContactComponent } from './_contact';
import { ProfileSkills } from './_skill';
import { addUserSkill, deleteUserSkill, updateProfileService, fetchAccountInfoService } from '../services';
import { mainYellowColor } from "helpers/constants";
import { setUser } from '../../../redux/userSlice';
import { useDispatch } from 'react-redux';
import Iconify from 'components/Iconify/index';
import useProfile from 'hooks/useProfile';
import styles from '../styles.module.scss';

//--------------------------------------------------------------------------------------------------

const Profile = () => {
  const dispatch = useDispatch();
  const { profile, skills, loading, skillsLoading, setProfile, setSkills, setLoading } = useProfile();
  const [newSkill, setNewSkill] = useState("");
  const [newSkillError, setNewSkillError] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (profile.selectedImage) {
      setSelectedImage(profile.selectedImage);
    }
  }, [profile.selectedImage]);

  const handleChange = (e) => {
    const { name, value } = e.target || { name: e.name, value: e.value };
    setProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageUpload = async (file) => {
    setSelectedImage(file);
    const formData = new FormData();
    formData.append('first_name', profile.firstName);
    formData.append('last_name', profile.lastName);
    formData.append('description', profile.desc);
    formData.append('address', profile.address);
    formData.append('occupation', profile.occupation);
    formData.append('phone_number', profile.phoneNumber);

    if (file instanceof File) {
      formData.append('avatar', file);
    }

    try {
      const response = await updateProfileService(formData);
      if (response.status === 201) {
        successSnackbar("Avatar updated successfully");
        const res = await fetchAccountInfoService();
        dispatch(setUser({
          first_name: res.data.first_name,
          last_name: res.data.last_name,
          avatar: res.data.avatar,
          id: res.data.id,
        }));
      }
    } catch (error) {
      if (error.response?.data?.message) {
        setFormErrors(error.response.data.message);
      } else {
        errorSnackbar("Failed to update avatar");
      }
    }
  };

  const handleAddSkill = async () => {
    if (!newSkill) {
      setNewSkillError("Skill cannot be empty");
      return;
    }
    try {
      const res = await addUserSkill({ name: newSkill });
      if (res.status === 201) {
        setSkills(prevSkills => [...prevSkills, { id: res.data.id, name: res.data.name }]);
        setNewSkill("");
        setNewSkillError("");
      }
    } catch (error) {
      console.error(error);
      errorSnackbar(error?.response?.data?.message || "Failed to add skill");
    }
  };

  const handleDeleteSkill = async (skill) => {
    try {
      const res = await deleteUserSkill(skill.id);
      if (res.status === 204) {
        setSkills(prevSkills => prevSkills.filter(s => s.id !== skill.id));
      }
    } catch (error) {
      console.error(error);
      errorSnackbar(error?.response?.data?.message || "Failed to delete skill");
    }
  };

  const handleSaveProfile = async () => {
    setLoading(true);
    setFormErrors({});

    const formData = new FormData();
    formData.append('first_name', profile.firstName);
    formData.append('last_name', profile.lastName);
    formData.append('description', profile.desc);
    formData.append('address', profile.address);
    formData.append('occupation', profile.occupation);
    formData.append('phone_number', profile.phoneNumber);

    try {
      const response = await updateProfileService(formData);
      if (response.status === 201) {
        successSnackbar("Profile updated successfully");
        const res = await fetchAccountInfoService();
        dispatch(setUser({
          first_name: res.data.first_name,
          last_name: res.data.last_name,
          avatar: res.data.avatar,
          id: res.data.id,
        }));
      }
    } catch (error) {
      if (error.response?.data?.message) {
        setFormErrors(error.response.data.message);
      } else {
        errorSnackbar("Failed to update profile");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={4} className={styles.profileMainGridBox}>
      <Grid item xl={4} md={4}>
        <Card sx={{ boxShadow: 3 }} className={styles.cardImage}>
          <Box>
            <FileUploader
              handleChange={handleImageUpload}
              name="file"
              types={["JPG", "PNG", "GIF", "JPEG"]}>
              {selectedImage ? (
                <img
                  alt="profile"
                  src={selectedImage instanceof File ? URL.createObjectURL(selectedImage) : selectedImage}
                  className="rounded-circle"
                  style={{ cursor: 'pointer', objectFit: 'cover', width: 200, height: 200 }}
                />
              ) : (
                <Skeleton variant="circular" width={200} height={200} />
              )}
            </FileUploader>
          </Box>

          <Box sx={{ textAlign: 'center' }}>
            <Typography className={styles.nameIntroInCard}>
              {`${profile.firstName} ${profile.lastName}`}
            </Typography>
            <Typography className={styles.nameIntro1InCard}>
              {`${profile.occupation} | ${profile.address}`}
            </Typography>
            <Box className={styles.boxTaskAction}>
              <Badge badgeContent={9} color="error" sx={{ marginRight: "24px", marginTop: '10px' }}>
                <Iconify icon="codicon:project" width={32} height={32} color={mainYellowColor} />
              </Badge>
              <Badge badgeContent={6} color="error" sx={{ marginRight: "24px", marginTop: '10px' }}>
                <Iconify icon="fluent-mdl2:add-friend" width={32} height={32} color={mainYellowColor} />
              </Badge>
              <Badge badgeContent={21} color="error" sx={{ marginTop: '10px' }}>
                <Iconify icon="material-symbols:task-alt" width={32} height={32} color={mainYellowColor} />
              </Badge>
            </Box>
            {loading ? <Skeleton width="100%" height={100} /> : <span></span>}
          </Box>
        </Card>
      </Grid>

      <Grid item xl={8} md={8}>
        <ProfileComponent
          profile={profile}
          loading={loading}
          handleChange={handleChange}
          handleSaveProfile={handleSaveProfile}
          formErrors={formErrors}
        />
      </Grid>

      <Grid item xl={12} md={12} className={styles.contactInfoSection}>
        <ContactComponent
          profile={profile}
          loading={loading}
          handleChange={handleChange}
          formErrors={formErrors}
        />
      </Grid>

      <Grid item xl={12} md={12}>
        <ProfileSkills
          skills={skills}
          skillsLoading={skillsLoading}
          handleDeleteSkill={handleDeleteSkill}
          newSkill={newSkill}
          setNewSkill={setNewSkill}
          handleAddSkill={handleAddSkill}
          newSkillError={newSkillError}
        />
      </Grid>
    </Grid>
  );
};

export default Profile;
