import * as React from 'react';
import { Box, Popper, IconButton, Fade, ClickAwayListener } from '@mui/material';
import { updateTaskOrder } from './services';

import { successSnackbar, errorSnackbar } from 'components/Snackbar/index';

import Iconify from 'components/Iconify/index';
import { btnPrimaryColor, mainRedColor, mainYellowColor } from "helpers/constants";
import styles from './styles.module.scss';

export default function ActionComponent({
  setUserDialogOpen,
  setNoteDialogOpen,
  setConfirmDialogOpen,
  setDateRangeDialogOpen,
  handleCommentToggle,
  handleAddSubtask,
  tasks,
  setTasks,
  taskIndex,
  mileStoneId
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState('bottom-end');

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const moveTask = async (fromIndex, toIndex) => {
    const updatedTasks = [...tasks];
    const [movedTask] = updatedTasks.splice(fromIndex, 1);
    updatedTasks.splice(toIndex, 0, movedTask);

    setTasks(updatedTasks);
    try {
      await updateTaskOrder(mileStoneId, updatedTasks);
      successSnackbar('Task order updated successfully');
    } catch (error) {
      errorSnackbar('Error updating task order:', error.message);
      console.error(error);
    }
  };

  return (
    <Box className="d-flex align-items-start">
      <Box className={styles.taskReorderButtons}>
        {tasks.length > 1 && (<>
          {taskIndex > 0 && (
            <IconButton onClick={() => moveTask(taskIndex, taskIndex - 1)}>
              <Iconify icon="ph:arrow-circle-up-bold" width={30} height={30} color={btnPrimaryColor} />
            </IconButton>
          )}
          {taskIndex < tasks.length - 1 && (
            <IconButton onClick={() => moveTask(taskIndex, taskIndex + 1)}>
              <Iconify icon="ph:arrow-circle-down-bold" width={30} height={30} color={btnPrimaryColor} />
            </IconButton>
          )}
        </>)}
      </Box>
      <IconButton onClick={handleClick('bottom-end')}>
        <Iconify icon="icon-park-solid:more-two" width={30} height={30} color={btnPrimaryColor} />
      </IconButton>
      <Popper
        sx={{ zIndex: 100 }}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Fade {...TransitionProps} timeout={350}>
              <Box className={styles.optionBoxAdminList}>
                <Box className={styles.optionItem} onClick={() => { handleAddSubtask(); setOpen(false); }}>
                  <Iconify icon="gala:add" width={30} height={30} color={mainYellowColor} />
                  <span className={styles.optionText}>Add Subtask</span>
                </Box>
                <Box className={styles.optionItem} onClick={() => { handleCommentToggle(); setOpen(false); }}>
                  <Iconify icon="la:comment-solid" width={30} height={30} color={mainYellowColor} />
                  <span className={styles.optionText}>Comment</span>
                </Box>
                <Box className={styles.optionItem} onClick={() => { setNoteDialogOpen(true); setOpen(false); }}>
                  <Iconify icon="fluent:note-48-regular" width={30} height={30} color={mainYellowColor} />
                  <span className={styles.optionText}>Note</span>
                </Box>
                <Box className={styles.optionItem} onClick={() => { setUserDialogOpen(true); setOpen(false); }}>
                  <Iconify icon="iwwa:assign" width={30} height={30} color={mainYellowColor} />
                  <span className={styles.optionText}>Assign to task</span>
                </Box>
                <Box className={styles.optionItem} onClick={() => { setDateRangeDialogOpen(true); setOpen(false); }}>
                  <Iconify icon="carbon:calendar" width={30} height={30} color={mainYellowColor} />
                  <span className={styles.optionText}>Due Date</span>
                </Box>
                <Box className={styles.optionItem} onClick={() => { setConfirmDialogOpen(true); setOpen(false); }}>
                  <Iconify icon="ph:x-circle-bold" width={30} height={30} color={mainRedColor} />
                  <span className={styles.optionText}>Delete</span>
                </Box>
              </Box>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Box>
  );
}
