import React, { useState } from 'react';
import { Dialog, Typography, Button, Box } from '@mui/material';
import InputBase from 'components/Input/InputBase';
import { mainYellowColor } from "helpers/constants";
import Iconify from 'components/Iconify/index';
import styles from './styles.module.scss';

const AddTaskDialogComponent = ({
  open,
  onClose,
  milestone,
  handleAddTask,
}) => {
  const [taskName, setTaskName] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = () => {
    if (taskName.trim()) {
      handleAddTask(milestone, taskName);
      setTaskName('');
      setError('');
      onClose();
    } else {
      setError('Please enter a task name.');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Box className={styles.noteDialogContainer}>
        <Typography variant="h5" className={styles.noteTitle}>Add Task</Typography>
        <Box className={styles.subtaskInputContainer}>
          <InputBase
            id="task-input"
            value={taskName}
            placeholder="Enter task name..."
            handleChange={(value) => setTaskName(value)}
            errorText={error}
            helperText={error}
            required
            sx={{ flexGrow: 1, marginRight: 2 }}
          />
        </Box>
        <Box>
          <Button onClick={handleSubmit} variant="contained" color="primary" className={styles.addBtnBase}>
            <span><Iconify icon="gala:add" width={24} height={24} color={mainYellowColor} /></span>
            Add
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AddTaskDialogComponent;
