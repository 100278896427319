import React from 'react';
import { Box, Typography } from '@mui/joy';
import Textarea from '@mui/joy/Textarea';

const TextAreaBase = (props) => {
  const {
    labelText,
    handleChange,
    errorText,
    placeholder,
    id,
    value,
    minRows,
    disabled = false,
    className,
    required,
  } = props;

  const primaryColor = '#9E9991';
  const primaryFont = 'Recursive Variable';
  const disabledColor100 = '#E5EAF2';
  const disabledColor500 = '#9DA8B7';

  return (
    <Box className={className} sx={{ display: 'flex', flexDirection: 'column' }}>
      {labelText && (
        <label htmlFor={id} style={{ marginBottom: '8px', fontWeight: 'bold' }}>
          {labelText}
        </label>
      )}
      <Textarea
        id={id}
        name={id}
        minRows={minRows || 3}
        placeholder={placeholder}
        value={value || ""}
        disabled={disabled}
        required={required}
        color={primaryColor}
        onChange={(e) => props?.keyword
          ? handleChange(props?.keyword, e.target.value)
          : handleChange(e.target.value)
        }
        sx={{
          fontFamily: primaryFont,
          fontSize: '1.125rem',
          padding: '14px',
          border: '1px solid',
          borderColor: errorText ? 'red' : primaryColor,
          borderRadius: '5px',
          '--Textarea-focusedHighlight': `0 !important`,
          '&:hover': {
            border: '1px solid',
            borderColor: errorText ? 'red' : '#666',
          },
          '&:focus': {
            borderColor: errorText ? 'red' : primaryColor,
            outline: 'none',
          },
          backgroundColor: disabled ? disabledColor100 : '#fff',
          color: disabled ? disabledColor500 : '#000',
        }}
      />
      {errorText && (
        <Typography variant="body2" color="error" sx={{ marginTop: '5px', fontSize: '0.875rem', color: 'red' }}>
          {errorText}
        </Typography>
      )}
    </Box>
  );
};


export default TextAreaBase;
