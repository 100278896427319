import React, { useState } from "react";
import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Row,
  Col,
  CardTitle,
  Spinner
} from "reactstrap";
import axios from 'axios';
import GoogleSignInButton from '../../components/Button/GoogleSignInButton';
import InputBase from 'components/Input/InputBase';
import InputPasswordBase from 'components/Input/InputPasswordBase';
import styles from './styles.module.scss';
import Iconify from 'components/Iconify/index';
import { iconPrimaryColor, APIURL } from "helpers/constants";
import { InputAdornment, IconButton, Button } from "@mui/material";
import { errorSnackbar } from 'components/Snackbar/index';

const Register = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
  });

  const [isRegistered, setIsRegistered] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const handleChange = (field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleRegister = async () => {
    const { email, password, firstName, lastName } = formData;
    const registrationData = new FormData();
    registrationData.append('email', email);
    registrationData.append('password', password);
    registrationData.append('first_name', firstName);
    registrationData.append('last_name', lastName);

    setLoading(true);
    try {
      const response = await axios.post(`${APIURL}/api/user/create`, registrationData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (response.data.status === 201) {
        setIsRegistered(true);
      }
    } catch (error) {
      console.error("error:: ", error);
      errorSnackbar(error?.response?.data?.message);
      setErrors(error.response.data || {});
    } finally {
      setLoading(false);
    }
  };

  const handleShowPass = () => {
    setShowPass(!showPass);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleRegister();
    }
  };

  return (
    <>
      {!isRegistered ? (
        <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <GoogleSignInButton action="Sign up" />
            <CardBody className="py-lg-4">
              <div className="text-center text-muted mb-3">
                <small>Sign up with credentials</small>
              </div>
              <Form role="form" onKeyPress={handleKeyPress}>
                <FormGroup>
                  <InputBase
                    type="text"
                    handleChange={handleChange}
                    errorText={errors.firstName}
                    placeholder="First Name"
                    value={formData.firstName}
                    keyword="firstName"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="material-symbols:person" width={24} color={iconPrimaryColor} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errors.firstName && <div className="error-message">{errors.firstName[0]}</div>}
                </FormGroup>
                <FormGroup>
                  <InputBase
                    type="text"
                    handleChange={handleChange}
                    errorText={errors.lastName}
                    placeholder="Last Name"
                    value={formData.lastName}
                    keyword="lastName"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="material-symbols:person" width={24} color={iconPrimaryColor} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errors.lastName && <div className="error-message">{errors.lastName[0]}</div>}
                </FormGroup>
                <FormGroup>
                  <InputBase
                    type="email"
                    handleChange={handleChange}
                    errorText={errors.email}
                    placeholder="Email"
                    value={formData.email}
                    keyword="email"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="ic:baseline-email" width={24} color={iconPrimaryColor} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errors.email && <div className="error-message">{errors.email[0]}</div>}
                </FormGroup>
                <FormGroup>
                  <InputPasswordBase
                    showPass={false}
                    handleChange={handleChange}
                    errorText={errors.password}
                    placeholder="Password"
                    value={formData.password}
                    name="password"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon="material-symbols:lock" color={iconPrimaryColor} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPass} edge="end">
                            <Iconify icon={showPass ? 'mdi:eye-off' : 'mdi:eye'} color={iconPrimaryColor} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errors.password && <div className="error-message">{errors.password[0]}</div>}
                </FormGroup>

                <div className="text-center">
                  <Button onClick={handleRegister} variant="contained" className={styles.submitBtn}>
                    {loading ? <Spinner size="sm" /> : 'Register'}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-2 justify-content-center">
            <a href="/login" className={styles.customeALink}>
              Already have an account?
            </a>
          </Row>
        </Col>
      ) : (
        <Col lg="6" md="8">
          <Card className={`bg-secondary shadow border-0 ${styles.customMessageCard}`}>
            <CardBody className="px-lg-3 py-lg-7 text-center">
              <CardTitle className="mb-4" style={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'lightgreen' }}>Success message</CardTitle>
              <div style={{ fontSize: '1.2rem', color: 'white' }}>
                Please wait for activation before you proceed
              </div>
            </CardBody>
          </Card>
        </Col>
      )}
    </>
  );
};

export default Register;
